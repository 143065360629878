import * as React from 'react';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Gauge from "./gauge";
import {Fragment, useEffect, useState} from "react";
import authService from "../api-authorization/AuthorizeService";
import SimpleBackdrop from "../backdrop";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import NumberCount from "./numberCount";
import {DatePicker} from "@mui/x-date-pickers";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import moment from 'moment';
import {
    LineChart,
    lineElementClasses,
    markElementClasses,
} from '@mui/x-charts/LineChart';
import {XAxis, YAxis} from "recharts";
import {styled} from "@mui/material/styles";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from "@mui/material/Table";
import PctChangeArrowTable from "./PctChangeArrowTable";
import Tab from "@mui/material/Tab";
import {Link} from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Title from "../Title";
import MDEditor, {commands} from "@uiw/react-md-editor";
import LoadingButton from "@mui/lab/LoadingButton";
import IssueList from "../issues/issuelist";
import VideobuummaryCard from "../videoSummary";
import {Snackbar} from "@mui/material";
import ChannelDetails from "./channelDetails";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';

const issueStatus = [
    { "key":1, "text": "Open"} ,
    { "key":2, "text": "Hold - Pending Review"},
    { "key":3, "text": "Open - Awaiting response"},
    { "key":4, "text": "General hold"},
    { "key":100, "text": "Closed"},
    { "key":101, "text": "Closed - no action required"},
    { "key":102, "text": "Closed - resolved"},
    { "key":103, "text": "Closed - unresolved"},
    { "key":199, "text": "Closed - not an issue"}
];

export default function Reporting({updateTitle}) {

    const [reports, setReports] = useState(null);
    const [loading, setLoading] = useState(false);

    const [reportStartDate, setReportStartDate] = useState(moment().subtract(1, 'month').startOf('month'));
    const [reportEndDate, setReportEndDate] = useState(moment());
    const [chartData, setChartData] = useState([]);
    const [xLabels, setxLabels] = useState([]);

    const [dataType, setDataType] = useState(0);
    const [dataObject, setDataObject] = useState(null);

    const [open, setOpen] = React.useState(false);

    const addChartData = (data) => {

        const keysVideos = Object.keys(data.videos_discovered);
        const valuesVideos = Object.values(data.videos_discovered);

        const every10thKeysVideos = [];
        const every10thValuesVideos = [];

        for (let i = 0; i < keysVideos.length; i += 10) {
            every10thKeysVideos.push(keysVideos[i]);
            every10thValuesVideos.push(valuesVideos[i]);
        }

        const keysPressure = Object.keys(data.overall_ranking_history);
        const valuesPressure = Object.values(data.overall_ranking_history);

        const every10thKeysPressure = [];
        const every10thValuesPressure = [];

        for (let i = 0; i < keysPressure.length; i += 10) {
            every10thKeysPressure.push(keysPressure[i]);
            every10thValuesPressure.push(round(valuesPressure[i],2));
        }
        
        //video additions 
        setChartData([{
            data: every10thValuesVideos.reverse(),
            label: "Videos per day"
        },{
            data: every10thValuesPressure.reverse(),
            label: "Pressure"
        }]);

        setxLabels(every10thKeysVideos.reverse());
    }
    const fetchData = async (start, end) => {
        setLoading(true);
        const response = await fetch('/api/reporting?start=' + start.format('YYYY-MM-DD') + '&end=' + end.format('YYYY-MM-DD'));
        const data = await response.json();
        setReports(data);
        addChartData(data);
        setLoading(false);
    }
    useEffect(() => {
        updateTitle('Reports')
        console.log('use effect');
        fetchData(reportStartDate, reportEndDate)
            // make sure to catch any error
            .catch((e)=> {
                console.error(e);
                setLoading(false);
            });
    }, [])

    const round = (value, exp) => {
        if (typeof exp === 'undefined' || +exp === 0)
            return Math.round(value);

        value = +value;
        exp  = +exp;

        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
            return NaN;

        // Shift
        value = value.toString().split('e');
        value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
    }

    function getTotalDaysDifference(dates) {
        if (dates.length < 2) {
            throw new Error('Array must contain at least two dates to calculate the difference.');
        }

        // Helper function to convert a date string 'dd-mm-yyyy' to a Date object
        function parseDate(dateStr) {
            const [day, month, year] = dateStr.split('-').map(Number);
            return new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date
        }

        const newestDate = parseDate(dates[0]);
        const oldestDate = parseDate(dates[dates.length - 1]);

        // Calculate the difference in time
        const timeDifference = newestDate - oldestDate;

        // Convert time difference from milliseconds to days
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

        return Math.round(daysDifference); // Rounding to the nearest whole number
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDataType(0)
        setOpen(open);
    };
    
 
    const loadData = async (type, id) => {
        setOpen(true)
        toggleDrawer(true);
        
        let url = "";
        if(type === 1){
            url = '/api/channels/details/' + id;
        }else if (type === 2){
            //TODO: Support multiple channels here - maybe get it from the data object itself
            window.open('https://outboundl.ink/?https://youtube.com/watch?v=' + id.video_id,'_blank');
            return;
        }else if (type === 3){
            
        }
        //1 : channel
        //2 : video
        //3 : issue

        setLoading(true);
        const response = await fetch(url, {

        });
        const data = await response.json();
        setDataType(type);
        setDataObject(data);
        setLoading(false);
    }
    const headerSummary = () => {
        return (
            <Grid container spacing={2} justifyContent={"space-evenly"}>
                <Grid item xs={3}>
                    <NumberCount text={"Videos discovered"} number={reports.count_number_of_videos} />
                </Grid>
                <Grid item xs={3}>
                    <NumberCount text={"Active Channels"} number={reports.count_active_channels} />
                </Grid>
                <Grid item xs={3}>
                    <NumberCount text={"Hours of video"} number={reports.count_hours_of_video} />
                </Grid>
                <Grid item xs={3}>
                    <NumberCount text={"Hrs of video/entry"} number={reports.count_hours_video_per_match} />
                </Grid>

                <Grid item xs={3}>
                    <NumberCount text={"Reports to projects"} number={reports.count_report_entries} />
                </Grid>
                <Grid item xs={3}>
                    <NumberCount text={"Entries waiting review"} number={reports.entries_pending_review} />
                </Grid>
                <Grid item xs={3}>
                    <NumberCount text={"New issue change"} number={round(reports.issue_create_percent,1) + "%"} />
                </Grid>
                <Grid item xs={3}>
                    <NumberCount text={"New video percent"} number={round(reports.video_percent_change,1) + "%"} />
                </Grid>
            </Grid>
        );
    };

    const StatTypography = styled(Typography)(() => ({
        fontSize: '12px',
        color:'#9a9a9a',textAlign: 'center'
    }));

    function getPercentageChange(numbers) {
        if (numbers.length < 2) {
            throw new Error('Array must contain at least two numbers to calculate percentage change.');
        }

        const newest = numbers[0];
        const oldest = numbers[numbers.length - 1];

        // Calculate the percentage change 
        let percentageChange = ((newest - oldest) / oldest) * 100;
        if (!isFinite(percentageChange)) {
            percentageChange = 1000;
        }
        return percentageChange;
    }


    const chanelTable = (rows)=> {
        return (
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Channel</TableCell>
                            <TableCell align="right">Pressure</TableCell>
                            <TableCell align="right">Change</TableCell> 
                            <TableCell align="right">Total Videos</TableCell>
                            <TableCell align="right">Platform</TableCell>
                            <TableCell align="right">Days Between Video</TableCell>
                            <TableCell align="right">Last Video</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.channel.id}
                                onClick={(event) =>loadData(1, row.channel.id)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.channel.handle}
                                </TableCell>
                                <TableCell align="right">{row.channel.pressure_ranking}</TableCell>
                                <TableCell align="right"><PctChangeArrowTable data={row.rankings} /></TableCell>
                                <TableCell align="right">{row.channel.total_discovered_videos}</TableCell>
                                <TableCell align="right">{row.channel.channel_type}</TableCell>
                                <TableCell align="right">{row.channel.average_days_between_video}</TableCell>
                                <TableCell align="right">{moment(row.channel.last_posted).isAfter(moment().subtract(7, 'days')) ? moment(row.channel.last_posted).fromNow() : moment(row.channel.last_posted).format('YYYY-MM-DD')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const videoTable = (rows)=> {
        return (
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Channel</TableCell>
                            <TableCell align="right">Pressure</TableCell>
                            <TableCell align="right">Change</TableCell>
                            <TableCell align="right">Last Video</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                onClick={(event) =>loadData(2, row.video)}
                                key={row.video.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.video.video_title}
                                </TableCell>
                                <TableCell align="right">{row.video.pressure_ranking}</TableCell>
                                <TableCell align="right"><PctChangeArrowTable data={row.rankings} /></TableCell>
                                <TableCell align="right">{moment(row.video.date_received).isAfter(moment().subtract(7, 'days')) ? moment(row.video.date_received).fromNow() : moment(row.video.date_received).format('YYYY-MM-DD')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const issuesTable = (rows)=> {
        return (
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Channel</TableCell>
                            <TableCell align="right">Pressure</TableCell>
                            <TableCell align="right">Change</TableCell>
                            <TableCell align="right">Last Video</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                onClick={(event) =>loadData(3, row.id)}
                                key={row.video.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.video.video_title}
                                </TableCell>
                                <TableCell align="right">{row.video.pressure_ranking}</TableCell>
                                <TableCell align="right"><PctChangeArrowTable data={row.rankings} /></TableCell>
                                <TableCell align="right">{moment(row.video.date_received).isAfter(moment().subtract(7, 'days')) ? moment(row.video.date_received).fromNow() : moment(row.video.date_received).format('YYYY-MM-DD')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

 
    const channelDetail = () => {
        return (
            <Box>
                <ChannelDetails channel={dataObject} />
            </Box>
        )
    }
    const videoDetail = () => {
        return (
            <Box>video</Box>
        )
    }
    const issueDetail = () => {
        return (
            <Box>Issue</Box>
        )
    }
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    }));
    
    const dateChangedStart = (d) =>{
        let date = moment(d);
        setReportStartDate(date);
        setTimeout(()=>{
            refreshData(date, reportEndDate);
        },100)
    }
    const dateChangedEnd = (d) =>{
        let date = moment(d);
        setReportEndDate(date);
        setTimeout(()=>{
            refreshData(reportStartDate, date);
        },100)
    }
    const refreshData = (start,end) => {
        fetchData(start, end)
            // make sure to catch any error
            .catch((e)=> {
                console.error(e);
                setLoading(false);
            });
    }
    const reportDisplay = () => {
        return (
            <Box>
                <Grid container spacing={0}>
                    <Grid item xs={7} alignItems={"center"} alignContent={"middle"}>
                        <h1 style={{margin:0}}>Pressure Report</h1>
                    </Grid>

                    <Grid item  xs={5}>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={6} alignItems={"center"}>
                                <DatePicker label="Start" defaultValue={reportStartDate}
                                            format="DD/MM/YYYY"
                                            maxDate={reportEndDate} onChange={dateChangedStart}
                                            minDate={moment().subtract(1, 'year')} />
                            </Grid>
                            <Grid item xs={6}>
                                <DatePicker label="End" defaultValue={reportEndDate}
                                            minDate={reportStartDate}
                                            format="DD/MM/YYYY"
                                            maxDate={moment()} onChange={dateChangedEnd} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{my:3}} />
                <Grid container spacing={0} sx={{mt:2}}>
                    <Grid item xs={6} direction="row"
                          justifyContent="flex-end"
                          alignItems="center">
                        <Stack direction="column"
                               justifyContent="flex-end"
                               alignItems="center">
                        <Typography sx={{fontWeight:"bold"}}>Current Pressure</Typography>
                            <Typography variant={"h3"}  sx={{mb:2, textAlign:"center"}}>{round(reports.overall_pressure_rating,2)}</Typography>
                            <Gauge needleRotation={(reports.overall_pressure_rating * 18)} percentChanged={getPercentageChange(Object.values(reports.overall_ranking_history))} daysDuration={getTotalDaysDifference(Object.keys(reports.overall_ranking_history))}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}  alignItems={"center"}
                         
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }}
                        >
                        {headerSummary()}
                            <LineChart
                                series={chartData}
                                height={200}
                                disableHighlight
                                disableLineItemHighlight
                                xAxis={[{ scaleType: 'point', data: xLabels }]}
                                leftAxis={null}
                                margin={{
                                    left: 30,
                                    right: 10,
                                    top: 30,
                                    bottom: 10,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Divider sx={{my:3}} />

                <Typography variant={"h4"}  sx={{mb:3, textAlign:"center"}}>Issue Summary</Typography>
                <Grid container spacing={5}>
                    <Grid item xs={3}>
                        <NumberCount number={reports.count_total_issues_created} />
                        <StatTypography>Total issues created during this period</StatTypography>
                    </Grid>
                    <Grid item xs={3}>
                        <NumberCount number={reports.entities_in_issues} />
                        <StatTypography>The total number of entities referenced in issues created</StatTypography>
                    </Grid>
                    <Grid item xs={3}>
                        <NumberCount number={reports.count_average_issue_lifepsan_days} />
                        <StatTypography>Average number of days from issue creation to a resolution</StatTypography>
                    </Grid>
                    <Grid item xs={3}>
                        <NumberCount number={reports.count_entries_reviewed_no_issue} />
                        <StatTypography>Entries reviewed with there being no issue</StatTypography>
                    </Grid>
                </Grid>
        
                <Divider sx={{mb:3,mt:3}} />
                <Grid container spacing={5}>
                    {Object.entries(reports.issue_creation).map(([period, count]) => (
                        <Grid item xs={2}>
                            <NumberCount number={count} />
                            <StatTypography>{period}</StatTypography>
                        </Grid>
                    ))}

                    <Grid item xs={4}>
                        <NumberCount number={"Closed - Resolved"} />
                        <StatTypography>The most common resolution status</StatTypography>
                    </Grid>
                </Grid>
                
                <Divider sx={{mb:10,mt:3}} />
                <Box sx={{mb:10}}>
                    <Typography variant={"h4"}  sx={{mb:3, textAlign:"center"}}>High Pressure Channels</Typography>
                    {chanelTable(reports.channel_reports)}
                </Box>
                <Box  sx={{mb:10}}>
                    <Typography variant={"h4"}  sx={{mb:3, textAlign:"center"}}>High Pressure Videos</Typography>
                    {videoTable(reports.video_reports)}
                </Box>
                <Divider/>
                <Box>
                    <h1>Issues</h1>
                    {reports.issue_reports.map((e) => {
                        return (
                            <div>
                                <strong>{e.id}</strong>
                            </div>
                        )
                    })}
                </Box>

                <Fragment>
                    <CssBaseline />
                    <Drawer
                        sx={{
                            zIndex: 1000,
                            width: {xs:'100%', md:"65%"},
                            [`& .MuiDrawer-paper`]: {
                                width: {xs:'100%', md:"65%"},
                            },
                        }}
                        anchor='right'
                        open={open}
                        onClose={toggleDrawer(false)}
                    >
                        <DrawerHeader>
                            <IconButton  onClick={toggleDrawer(false)}>
                                <ChevronLeftIcon />
                            </IconButton>
                            Details
                        </DrawerHeader>
                        <Box sx={{px: 2}}> 
                            {dataType === 1 && channelDetail()}
                            {dataType === 2 && videoDetail()}
                            {dataType === 3 && issueDetail()}
                        </Box>
                    </Drawer>
    
                </Fragment>
            </Box>
        )
    }
    
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', mt:2 }}>

            <Box sx={{m:2}}>
                {reports && (
                    reportDisplay()
                )}
            </Box>


            <SimpleBackdrop open={loading}/>

        </Paper>
    )
}
