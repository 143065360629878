import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {useEffect} from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Box from "@mui/material/Box";
import Title from "./Title";
import {red} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import {Backdrop, BottomNavigation, BottomNavigationAction, CardHeader, Chip, Select, styled} from "@mui/material";
import BugReportIcon from '@mui/icons-material/BugReport';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DoNotTouchIcon from '@mui/icons-material/DoNotTouch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import IssueList from "./issues/issuelist";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import authService from './api-authorization/AuthorizeService'
import moment from "moment/moment";
import {Link, useNavigate} from 'react-router-dom';
import Tooltip from "@mui/material/Tooltip";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Tab from "@mui/material/Tab";
import {TabList} from "@mui/lab";
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import ExpandableText from "./moreText";
import Alert from "@mui/material/Alert";
import CheckIcon from '@mui/icons-material/Check';
import CustomPaginationActionsTable from "./reportEntryUrlTable";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DetailsIcon from '@mui/icons-material/Details';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import MoneyIcon from "@mui/icons-material/Money";
import Menu from "@mui/material/Menu";
import MoreVertIcon from '@mui/icons-material/MoreVert';


/* 
    TODO: Enable call to: details/{projectId}/report/viewed/{reportEntryId}
 */

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1, // Ensure the dialog is above other elements
}));

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
    zIndex: (theme) =>
        Math.max.apply(Math, Object.values(theme.zIndex)) + 1, // Ensure the backdrop is above the dialog
    color: '#fff',
}));

const BoldWord = ({ text, wordToBold }) => {
  // Split the text into parts around the target word
  const parts = text.split(new RegExp(`(${wordToBold})`, 'gi'));

  return (
    <div>
      {parts.map((part, index) => (
        // Apply bold styling if the part matches the wordToBold
        part.toLowerCase() === wordToBold.toLowerCase() ? 
        <span key={index} style={{backgroundColor:'#FFD233', fontWeight:800}}>{part}</span> : 
        part
      ))}
    </div>
  );
};


const MyStack = styled(Stack)(({ theme, isConditionTrue }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    borderWidth: 2,
    borderStyle: 'solid',
    ...(isConditionTrue
        ? {
            borderColor: 'red',
            backgroundColor: 'rgba(255,230,230,0.2)',
        }
        : {
            borderColor: 'orange',
            backgroundColor: 'rgba(255,165,0,0.2)', // Faded orange background
        }),
}));

export default function ReportEntry({report, project, readonly = false}) {
    console.log(report);

    const [value, setValue] = React.useState(-1);
    const [issues, setIssues] = React.useState([]);
    const [newIssueNotes, setNewIssueNotes] = React.useState("");
    const [issueTitle, setNewIssueTitle] = React.useState("");
    const [newIssueStatus, setNewIssueStatus] = React.useState(1);
    const navigate = useNavigate();
    const issueStatus = [
        { "key":1, "text": "Open"} ,
        { "key":2, "text": "Hold - Pending Review"},
        { "key":3, "text": "Open - Awaiting response"},
        { "key":4, "text": "General hold"},
        { "key":100, "text": "Closed"},
        { "key":101, "text": "Closed - no action required"},
        { "key":102, "text": "Closed - resolved"},
        { "key":103, "text": "Closed - unresolved"},
        { "key":199, "text": "Closed - not an issue"}
    ];
    const [openNewIssue, setopenNewIssue] = React.useState(false);
    const handleCloseNewIssue = () => {
        setopenNewIssue(false);
    };

    const [openModal, setOpenModal] = React.useState(false);
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleNewIssueStatusChange = (event) => {
        setNewIssueStatus(event.target.value);
    };
    const handleNewIssueNotesChange = (event) => {
        console.log(event);
        console.log(event.target.value);
        setNewIssueNotes(event.target.value);
    };
    const handleNewIssueTitleChange = (event) => {
        console.log(event);
        console.log(event.target.value);
        setNewIssueTitle(event.target.value);
    };
    const issueCreate = () => {
        createIssueWithNotes();
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 100,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        
    };
    const [openExistingIssue, setopenExistingIssue] = React.useState(false);
    const [existingIssue, setExistingIssue] = React.useState(-1);
    const [allOpenIssues, setAllOpenIssues] = React.useState([]);
    const handleCloseExistingIssue = () => { 
        setopenExistingIssue(false);
    };

    
    
    const fetchAllOpenIssues = async () => {
        console.log('getting issues', report);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setAllOpenIssues(data.reverse());
    }
    
    const fetchIssues = async () => {
        console.log('getting issues', report);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/report/' + report.report_entry_id + '/video/' + report.media.id, {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setIssues(data.reverse());
    }
    
    useEffect(() => {
        fetchIssues()
            // make sure to catch any error
            .catch(console.error);

        fetchAllOpenIssues().catch(console.error);
    }, [report])
    
    const createNoIssue = async () => {

        var req = {
            status: 199,
            channel_video_ids: [report.media.id],
            comment: "",
            title: "No issue",
            project_id: project.id
            
        };
        //const token = await authService.getAccessToken();

        const response = await fetch('/api/issues', {
            method: "POST",
            headers:  {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        fetchIssues();
    }
    const createIssueWithNotes = async () => {

        var req = {
            status: newIssueStatus,
            channel_video_ids: [report.media.id],
            comment: newIssueNotes,
            title: issueTitle,
            project_id: project.id
        };
        //const token = await authService.getAccessToken();

        const response = await fetch('/api/issues', {
            method: "POST",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();

 
        navigate('/issues/' + data.id);
    }
    const logNoIssueNoAction = async () => {
        await createNoIssue().then(()=>{
            setOpenModal(false);
            fetchIssues()
                // make sure to catch any error
                .catch(console.error);
        }).catch((er)=>{
            console.log(er);
            setOpenModal(false);
        })
    }
    
    const issueAssignExisting = async () =>{
        console.log('assign to existing');
        let req = {};
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/' + existingIssue + '/entity/' + report.media.id + '/' + project.id, {
            method: "POST",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        if (response.ok) {
            // Redirect to "/issues/" on success
            navigate('/issues/' + existingIssue);
        } else {
            // Handle error if needed
        }
    }

    const handleExistingIssueChange = (event) => {
        console.log(event);
        console.log(event.target.value);
        setExistingIssue(event.target.value);
    };
    const extractResultKeys = (jsonData) => {
        // Array to store the keys
        let resultKeys = [];
        console.log('extract')
        console.log(jsonData);
        // Checking if 'image_data' is present and is an array
       
            // Iterate over each item in 'image_data'
            jsonData.forEach(item => {
                // Check if 'inference' and 'result' objects exist
                if (item.inference && item.inference.result) {
                    // Add the keys of 'result' object to the array
                    Object.keys(item.inference.result).forEach(key => {
                        if (!resultKeys.includes(key)) {
                            resultKeys.push(key);
                        }
                    }); 
                }
            });
        

        return resultKeys;
    }
    const [downloadingScreenshot, setDownloadingScreenshot] = React.useState(false);
    const [currentDownloadId, setCurrentDownloadId] = React.useState(0);
    const slugify = (title) => {
        return title
            .trim()
            .replace(/ +/g, '-')
            .toLowerCase()
            .replace(/[^a-z0-9-]/g, '')
    }

    const doDownloadScreenshot = async (id) =>{
        console.log('downloading');
        setCurrentDownloadId(id);
        setOpenUrlLoadingBackdrop(true);
        setDownloadingScreenshot(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/'+project.id+'/details/match/url/' + id + '/file', {
            method: "GET",
            headers: {
                'Content-Type': 'image/jpeg' // Adjust according to the image type you expect
            }
        });
        setDownloadingScreenshot(false);
        if (response.ok) {
            setOpenUrlLoadingBackdrop(false);
            // Redirect to "/issues/" on success
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = slugify(moment(currentUrlDetails.request.DateGenerated).format("DD-MM-YYYY") +"----"+ currentUrlDetails.request.Parameters.Url) + ".jpg"; // Default file name
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setOpenUrlPreviewOpen(true);
        } else {
            // Handle error if needed
            setOpenUrlLoadingBackdrop(false);
            setScreenshotFailedOpen(true);
        }
    }

    function CustomTab() {
        let c = report.report_entries.filter(p=>p.visual_only && p.visual_only === true && p.visual_only_screenshot.labels.length > 0)
        const customLabel = (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <span>Visual Matches</span> <Chip label={c.length} />
            </Stack>
        );

        return customLabel;
    }
    function CustomTabAudibable() {
        let c = report.report_entries.filter(p=>!p.visual_only)
        const customLabel = (
            
              <Box direction={"row"} spacing={2} alignItems={"center"}>
                  <span>Audible matches</span>  <Chip label={c.length} />
              </Box>
  
        );

        return customLabel;
    }

    const [tabIndexvalue, settabIndexValue] = React.useState(0);

    const handleTabIndexChange = (event, newValue) => {
        settabIndexValue(newValue);
    };
    const [tabUrlIndexvalue, setUrltabIndexValue] = React.useState(0);
    const handleUrlTabIndexChange = (event, newValue) => {
        console.log(newValue);
        setUrltabIndexValue(newValue);
    };

    function CustomUrlTabAffLink() {
        let c = currentUrlDetails.aff_urls_dest_page
        const customLabel = (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                <span>Affiliate links on page</span> <Chip label={c.length} />
            </Stack>
        );

        return customLabel;
    }
    function CustomUrlTabAllLink() {
        let c = currentUrlLinks.length;
        const customLabel = (

            <Box direction={"row"} spacing={2} alignItems={"center"}>
                <span>All Links</span>  <Chip label={c} />
            </Box>

        );

        return customLabel;
    }
    function CustomUrlTabRedirect() {
        let c = (currentUrlDetails.aff_urls_in_redirects ?? []).length;
        const customLabel = (

            <Box direction={"row"} spacing={2} alignItems={"center"}>
                <span>Affiliate Links In Redirect</span>  <Chip label={c} />
            </Box>

        );

        return customLabel;
    }
   function visualMatches() {
       const rows = report.report_entries.filter(p=>p.visual_only && p.visual_only === true && p.visual_only_screenshot.labels.length > 0 && p.is_dismissed === false);
        return (
            <Box component="div" sx={{ p: 0 }}>
                <Typography>These timestamps show any detection of brands visually.</Typography>
                {rows && rows.length === 0 && (
                    <Typography>No matches found</Typography>
                )}
                {rows && rows.length > 0 && (
                
                <TableContainer sx={{ maxHeight: 700 }}  component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Start Seconds</TableCell>
                                <TableCell>End Seconds</TableCell>
                                <TableCell>Labels (% confidence)</TableCell>
                                <TableCell>Image</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    hover
                                    key={row.id}
                                >
                                    <TableCell scope="row">
                                        <Button component="a" href={'https://outboundl.ink/?https://youtube.com/watch?v=' + report.media.video_id + '&t='+row.start_seconds}  target="_blank">
        
                                            {moment.utc(row.start_seconds*1000).format("mm:ss")}
                                        </Button>
        
                                    </TableCell>
                                    <TableCell>{moment.utc(row.end_seconds*1000).format("mm:ss")} ({row.end_seconds-row.start_seconds}s)</TableCell>
                                    <TableCell>{row.visual_only_screenshot.labels.map((row) => (
                                        <Chip label={row.label + " (" + Math.round(row.score*100, 1) + "%)" } key={row} />
                                    ))}</TableCell>
        
                                    <TableCell>
                                        <img src={'/api/projects/details/match/screenshot/' + row.visual_only_screenshot.id} width={300}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                )}
            </Box>
        )
    }
    
    function audibleMatches() {
        const rows = report.report_entries.filter(p=>!p.visual_only  && p.is_dismissed === false);
        return (<Box component="div" sx={{ p: 0 }}>
        <Typography>These timestamps are related to transcribed audio.</Typography>

            {rows && rows.length === 0 && (
                <Typography>No matches found</Typography>
            )}
            {rows && rows.length > 0 && (
            <TableContainer sx={{ maxHeight: 700 }}  component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Sentence</TableCell>
                        <TableCell align="right">Keyword</TableCell>
                        <TableCell>Labels</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            hover
                            key={row.id}
                        >
                            <TableCell scope="row">
                                <Button component="a" href={'https://outboundl.ink/?https://youtube.com/watch?v=' + report.media.video_id + '&t='+row.start_seconds}  target="_blank">

                                    {moment.utc(row.start_seconds*1000).format("mm:ss")}
                                </Button>

                            </TableCell>
                            <TableCell>{moment.utc(row.end_seconds*1000).format("mm:ss")} ({row.end_seconds-row.start_seconds}s)</TableCell>
                            <TableCell>{row.text && (<BoldWord text={row.text} wordToBold={row.keyword.keyword} />)}</TableCell>
                            <TableCell align="right">{row.keyword && (<Typography>{row.keyword.keyword.toLowerCase()}</Typography>)}</TableCell>
                            <TableCell>{extractResultKeys(row.image_data).map((row) => (
                                <Chip label={row} key={row} />
                            ))}</TableCell>
                            <TableCell>
                                <SegmentContextMenu r={row} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            )}
        </Box>)
    }
    
    async function startDismiss(r){
        console.log('dismissing', r);
        setOpenUrlLoadingBackdrop(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/details/'+project.id+'/pre/' + report.report_entry_id + '/segment/'+r.id+'/dismiss', {
            method: "POST"
        });
        if (response.ok) {
            setOpenUrlLoadingBackdrop(false);
            report.report_entries = report.report_entries.filter(e=>e.id != r.id);
            
        } 
    }

    function SegmentContextMenu({r}) {
        const [anchorEl2, setAnchorEl2] = React.useState(null);
        const openSegmentDismis = Boolean(anchorEl2);
        const handleClick2 = (event) => {
            setAnchorEl2(event.currentTarget);
        };
        const handleClose2 = () => {
            setAnchorEl2(null);
        };

        return (
            <div>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={openSegmentDismis ? 'long-menu' : undefined}
                    aria-expanded={openSegmentDismis ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick2}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl2}
                    open={openSegmentDismis}
                    onClose={handleClose2}

                >
                    <MenuItem key={1} onClick={()=>{startDismiss(r)}}>
                        Dismiss
                    </MenuItem>
                </Menu>
            </div>
        );
    }

    const [openUrlLoadingBackdrop, setOpenUrlLoadingBackdrop] = React.useState(false);
    const [screenshotFailedOpen, setScreenshotFailedOpen] = React.useState(false);
    const [openUrlPending, setUrlPendingOpen] = React.useState(false);
    const [openUrlPreviewOpen, setOpenUrlPreviewOpen] = React.useState(false);
    const [currentUrlDetails, setCurrentUrlDetails] = React.useState({"brands":[],"aff_urls_dest_page":[],"request":{"Parameters": {"Url": ""}}})
    const [currentUrlLinks, setCurrentUrlLinks] = React.useState([]);
    const [currentScreenshotObjective, setCurrentScreenshotObjective] = React.useState({});
    const [downloadAvailable, setDownloadAvailable] = React.useState(false);
    const [redirects, setRedirects] = React.useState([]);
    
    const handleScreenshotfailedClose = () => {
        setScreenshotFailedOpen(false);
    }
    const handleUrlPendingClose = () => {
        setUrlPendingOpen(false);
    };

    const handleBackdropClose = () => {
        //do nothing
    };
    const handleUrlPreviewClose = () => {
        setOpenUrlPreviewOpen(false);
    };
    const handleScreehotFailedClose = () => {
        setOpenUrlPreviewOpen(true);
        setScreenshotFailedOpen(false);
    }
    const openPreview = (urlId) => {
        console.log(urlId);
        setCurrentDownloadId(urlId);
        setOpenUrlLoadingBackdrop(true);
        setDownloadAvailable(false);
        setCurrentUrlLinks([]);
        setCurrentScreenshotObjective({});
        setRedirects([]);
        fetch("/api/projects/"+project.id+"/details/match/url/" + urlId + "/details", {
            method: "GET"
        })
            .then(async (response) => {
                const contentType = response.headers.get('content-type');
                setOpenUrlLoadingBackdrop(false);
                if (
                    response.status === 204
                ) {
                    setUrlPendingOpen(true);
                } else {
                    const data = await response.json();
                    setCurrentUrlDetails(data);
                    setOpenUrlPreviewOpen(true);
                    
                    var screenshotObjective = data.request.Objectives.filter(x=>x.Type=="screenshot");
                    if(screenshotObjective.length > 0) {
                        setCurrentScreenshotObjective(screenshotObjective[0]);
                        setDownloadAvailable(true);
                    }
                        
                    var linkObjective = data.request.Objectives.filter(x=>x.Type =="links");
                    if(linkObjective.length > 0) {
                        setCurrentUrlLinks(linkObjective[0].Result.Links);
                    }
                    
                    var redirectObjective = data.request.Objectives.filter(x=>x.Type =="redirect");
                    if(redirectObjective.length > 0) {
                        setRedirects(redirectObjective[0].Result.Urls);
                    }
                    
                }
            })
            .catch(() => {
                setOpenUrlLoadingBackdrop(false);
                console.log('No internet connection found. App is running in offline mode.');
            });
    }
    
    function downloadScreenshot(){
        setOpenUrlLoadingBackdrop(true);
        setOpenUrlPreviewOpen(false);
        doDownloadScreenshot(currentDownloadId).then((r)=>{console.log('')})
    }
    
    function loadingUrlBackdrop() {
       return (<StyledBackdrop
           sx={{ color: '#fff' }}
           open={openUrlLoadingBackdrop}
           onClick={handleBackdropClose}
       >
           <CircularProgress color="inherit" />
       </StyledBackdrop>)
    }

    let brandsInContent = report.brand_matches && report.brand_matches.length > 0;
    let linksInContent = report.brand_matches && report.brand_matches.filter(e=>e.links_redirects === true ||
        e.links_dest_links === true || e.links_url === true).length > 0;
    let visualInContent = report.brand_matches && report.brand_matches.filter(e=>e.visual_matches === true).length > 0;
    let isSerious = (brandsInContent === true && linksInContent === true && visualInContent === true );
    return (
        <div>
            <Box component="div" sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}>
                <Title>Issues</Title>
                <small>This is the description of the video, at the time it was analysed.</small>
                
               <MyStack direction={"column"} isConditionTrue={isSerious} spacing={1}>
                   {report.brand_matches && report.brand_matches.length > 0 && (
                       <Stack direction={"row"} spacing={2}  alignItems={"center"}>
                           <ReportProblemIcon style={{ color: isSerious ? 'red' : 'orange'}}/>
                           <Stack direction={"row"} spacing={1} alignItems={"center"}>
                               <Box>References</Box>
                               {report.brand_matches.map((row) => (
                                   <Chip label={row.brand_name} />
                               ))}
                           </Stack>
                       </Stack>
                   )}
                   {linksInContent === true && (
                       <Stack direction={"row"} spacing={2}  alignItems={"center"}>
                           <ReportProblemIcon style={{ color: isSerious ? 'red' : 'orange'}}/>
                           <Stack direction={"row"} spacing={1} alignItems={"center"}>
                               <Box>Links to your brand</Box>
                           </Stack>
                       </Stack>
                   )}
                   {visualInContent === true && (
                       <Stack direction={"row"} spacing={2}  alignItems={"center"}>
                           <ReportProblemIcon style={{ color: isSerious ? 'red' : 'orange'}}/>
                           <Stack direction={"row"} spacing={1} alignItems={"center"}>
                               <Box>Contains a visual representation of a brand</Box>
                           </Stack>
                       </Stack>
                   )}
                   {report.report_entries.filter(p=>!p.visual_only).length > 0 && (
                       <Stack direction={"row"} spacing={2}  alignItems={"center"}>
                           <ReportProblemIcon style={{ color: isSerious ? 'red' : 'orange'}}/>
                           <Stack direction={"row"} spacing={1} alignItems={"center"}>
                               <Box>Audio contains a target keyword </Box>
                           </Stack>
                       </Stack>
                   )}
               </MyStack>
            </Box>
            <Box component="div" sx={{ p: 1, borderBottom: 1, borderColor: 'divider' }}>
                <Title>Description</Title>
                <small>This is the description of the video, at the time it was analysed.</small>
               
                <ExpandableText text={report.description_at_report} maxLength={300} />
                {/* <h3>{report.title_at_report}</h3><p sx={{fontSize:'12px'}}>
                    {report.description_at_report.split("\n").map(function(item) {
                        return (
                    <span>{item}
                        <br/></span>
                        )
                    })}
                </p>*/}
            </Box>

            <Box component="div" sx={{ mb:3, p: 1, pb:3, borderBottom: 1, borderColor: 'divider' }}>
                <Title>Issues</Title>
                {!readonly && (
                    <Box component="div" sx={{ p: 0  }}>

                        <BottomNavigation
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                console.log(newValue);
                                setValue(newValue);
                                if(newValue === 0) {
                                    setopenNewIssue(true);
                                }else if(newValue === 20){
                                    //Create non issue
                                    setOpenModal(true);
                                    logNoIssueNoAction();
                                }else {
                                    //some other type of issue
                                    setopenExistingIssue(true);
                                }
                            }}
                        >
                            <BottomNavigationAction label="New Issue" icon={<BugReportIcon />} value={0} />
                            <BottomNavigationAction label="Assign Existing" icon={<AssignmentIcon />} value={10} />
                            <BottomNavigationAction label="No Issue" icon={<DoNotTouchIcon />} value={20} />
                        </BottomNavigation>
                    </Box>
                )}
                <Box>
                    {issues && issues.length > 0 && (
                        <IssueList issues={issues} />
                    )}
                    {!issues || issues && issues.length == 0 && (
                        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                            <Box width="30%">
                                <Alert icon={<CheckIcon fontSize="inherit" />} variant="outlined" severity="success">
                                    This video has no related issues.
                                </Alert>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>

            <Box component="div" sx={{ p: 0, borderBottom: 1, borderColor: 'divider' }}>

                <Box sx={{px:1}}>
                    <Title>Matches & Results</Title>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabIndexvalue} onChange={handleTabIndexChange} aria-label="" sx={{opacity:1}} >
                            <Tab label={CustomTabAudibable()} {...a11yProps(1)} sx={{ opacity: 1 }}/>
                            <Tab label={CustomTab()} {...a11yProps(0)} sx={{ opacity: 1 }}/>
                          
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabIndexvalue} index={1} sx={{backgroundColor:"#efefef"}}>
                        {visualMatches()}
                    </CustomTabPanel>
                    <CustomTabPanel value={tabIndexvalue} index={0}>
                        {audibleMatches()}
                    </CustomTabPanel>
                </Box>
            </Box>
            <Box component="div" sx={{ p: 1 }}>
                <Title>Urls</Title>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Published Url</TableCell>
                                <TableCell>Labels</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {report.media.urls.map((row) => (
                                <TableRow
                                    hover
                                    key={row.id}
                                >
                                    <TableCell>
                                        {(row.is_aff_link === true || row.aff_link_on_dest === true || row.affiliate_url && row.affiliate_url !== "" || row.aff_link_in_redirects === true) && (

                                             <MoneyIcon color={"warning"} sx={{opacity:0.7}}/>

                                        )}
                                    </TableCell>
                                    <TableCell><a href={`https://outboundl.ink/?${row.url}`} target="_blank"
                                                  rel="noopener noreferrer">{row.url}</a>
                                        {row.brands && row.brands.length > 0 && (
                                            <Alert severity={"warning"}>This link references {row.brands.length} brands</Alert>
                                            )}
                                    </TableCell>
                                    <TableCell>
                                        
                                        {row.inference.map((m)=>(
                                            <Chip label={m.label + " (" + Math.round(m.score*100, 1) + "%)" } key={m} />
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        <Button key={row.id} disabled={downloadingScreenshot} onClick={()=>{openPreview(row.id)}}>
                                             
                                            <DetailsIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography sx={{pt:2,mb:5}} variant="caption" display="block" gutterBottom>Note: Some URLS may not be analysised at all due to certain restrictions. Some URLS may use previous results if the analysis occured recently.</Typography>
            </Box>
            <Dialog open={openNewIssue} onClose={handleCloseNewIssue}>
                <DialogTitle>New issue</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <DialogContentText>
                           Enter details about the new issue
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="issue_title"
                            label="Issue Title (optional)"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={issueTitle}
                            onChange={handleNewIssueTitleChange}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="notes"
                            label="Issue notes"
                            rows={4}
                            fullWidth
                            multiline
                            variant="standard"
                            value={newIssueNotes}
                            onChange={handleNewIssueNotesChange}
                        />

                        <Select
                            value={newIssueStatus}
                            label="Status" fullWidth
                            onChange={handleExistingIssueChange}
                        >
                            {issueStatus.map((issue) => {
                                return (
                                    <MenuItem value={issue.key} key={issue.key}>{issue.text}</MenuItem>
                                );
                            })}
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNewIssue}>Cancel</Button>
                    <Button onClick={issueCreate}>Open issue</Button>
                </DialogActions>
            </Dialog>

            <Modal key={2}
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CircularProgress />
                </Box>
            </Modal>

            <Dialog open={openExistingIssue} onClose={handleCloseExistingIssue}>
                <DialogTitle>Existing issue</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <DialogContentText>
                            Enter details about the new issue
                        </DialogContentText>
                        

                        <Select
                            value={existingIssue}
                            label="Status" fullWidth
                            onChange={handleExistingIssueChange}
                        >
                            {allOpenIssues.map((issue) => {
                                return (
                                    <MenuItem value={issue.id} key={issue.id}>{issue.id}</MenuItem>
                                );
                            })}
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExistingIssue}>Cancel</Button>
                    <Button onClick={issueAssignExisting}>Open issue</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openUrlPending}
                onClose={handleUrlPreviewClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Waiting to analyse results"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This URL has not yet been analysed. Check back later
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUrlPendingClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={screenshotFailedOpen}
                onClose={handleScreenshotfailedClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Sorry :("}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We tried to make a screenshot, but it failed. Sometimes that happens. 
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleScreehotFailedClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <StyledDialog open={openUrlPreviewOpen} onClose={handleUrlPreviewClose}
                    maxWidth={"xl"} fullWidth={true}>
                <DialogTitle>URL Details </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <Stack direction="row" spacing={2} alignItems={"center"}>
                            <strong><span>URL:</span></strong>
                            <Chip label={((currentUrlDetails.request.Parameters.Url.length > 200) ? currentUrlDetails.request.Parameters.Url.substring(0, 200) + "..." : currentUrlDetails.request.Parameters.Url)} />
                        </Stack>
                    </Typography>
                    <Typography gutterBottom>
                        <Stack direction="column" spacing={0}>
                            <Box><strong>Date Analysied:</strong> {moment(currentUrlDetails.request.DateGenerated).format("DD MMM YYYY HH:mm")}</Box>
                            <Typography sx={{pt:0}} variant="caption" display="block" gutterBottom>This date may predate the video if we have analysed this URL previously</Typography>
                        </Stack>
                    </Typography>
                    <Stack direction={"column"} spacing={1} sx={{mb:1}}>
                        <Typography gutterBottom>
                            <strong>Response Status:</strong> {currentUrlDetails.request.ResponseStatus}
                        </Typography>
                        {currentUrlDetails && currentUrlDetails.request.ResponseStatus === 0 && (
                            <Alert icon={<ReportProblemIcon fontSize="inherit" />} variant="outlined" severity="error">
                                This status indicates we could not reach the URL in question. Network errors, server issues etc can cause this. Not all information shown may be accurate.
                            </Alert>
                            )}

                        {currentUrlDetails && (currentUrlDetails.request.ResponseStatus === 300 || currentUrlDetails.request.ResponseStatus === 301)  && (
                            <Alert icon={<ReportProblemIcon fontSize="inherit" />} variant="outlined" severity="warning">
                               This URL sends visitors to another web page. 
                            </Alert>
                        )}
                        
                    </Stack>
                   
                    <Typography gutterBottom>
                        <strong>Links On Page:</strong> {currentUrlLinks.length} found on page
                    </Typography>

                    
                        <Typography gutterBottom>
                            <strong>Redirected?</strong>
                            <Box>
                                <Stack direction="row" spacing={2} alignItems={"center"}>
                                    {redirects.length > 0 && (<span><CheckIcon  color="success" /> Final Url: {redirects[redirects.length-1]}</span>)}
                                    {!redirects || redirects && redirects.length == 0 && (
                                        <Stack direction="row" spacing={2} alignItems={"center"}>
                                            <CancelIcon color="action" /> <span>No redirects where detected.</span>
                                        </Stack>
                                    )}

                                </Stack>
                            </Box>
                        </Typography>


                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabUrlIndexvalue} onChange={handleUrlTabIndexChange} aria-label="" sx={{opacity:1}} >
                                    <Tab label={CustomUrlTabAffLink()} {...a11yProps(0)} sx={{ opacity: 1 }}/>
                                        <Tab label={CustomUrlTabRedirect()} {...a11yProps(1)} sx={{ opacity: 1 }}/>
                                        
                                   
                                    <Tab label={CustomUrlTabAllLink()} {...a11yProps(2)} sx={{ opacity: 1 }}/>
                                </Tabs>
                            </Box>
                        <CustomTabPanel value={tabUrlIndexvalue} index={1}>
                            <Box  sx={{p:0}}>
                                
                                <CustomPaginationActionsTable rows={currentUrlDetails.aff_urls_in_redirects} />
                            </Box>
                        </CustomTabPanel>
                            <CustomTabPanel value={tabUrlIndexvalue} index={0} sx={{backgroundColor:"#efefef"}}>
                                <Box sx={{p:0}}>
                                    <Box sx={{p:2}}>
                                        <Typography gutterBottom>
                                            <Stack direction="column" spacing={0}>
                                                <Box><strong>Brands linked on page:</strong> {currentUrlDetails.brands && currentUrlDetails.brands.filter(e=>e.dest_page && e.dest_page === true).map((row) => (
                                                    <Chip label={row.organisation_brand.name} />
                                                ))}</Box>
                                            </Stack>
                                        </Typography>
                                    </Box>
                                    <CustomPaginationActionsTable rows={currentUrlDetails.aff_urls_dest_page} />
                                </Box>
                            </CustomTabPanel>
                            <CustomTabPanel value={tabUrlIndexvalue} index={2}>
                                <Box  sx={{p:0}}>
                                    <CustomPaginationActionsTable rows={currentUrlLinks} />
                                    <Typography sx={{pt:2}} variant="caption" display="block" gutterBottom>Note: We do not analyse these links, they where found on {currentUrlDetails.request.Parameters.Url}</Typography>
                                </Box>
                            </CustomTabPanel>
                        
                    </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{downloadScreenshot()}} variant="outlined" color="success">Download Screenshot</Button>
                    <Button onClick={()=>{setOpenUrlPreviewOpen(false)}} variant="outlined" color="error">Close</Button>
                </DialogActions>
            </StyledDialog>
            {loadingUrlBackdrop()}
        </div>
    );
}
