import * as React from 'react';
import { useParams } from 'react-router-dom';
import {Fragment, useEffect} from "react";
import Grid from "@mui/material/Grid";
import ReportEntry from "../reportEntry";
import Drawer from "@mui/material/Drawer";
import AppBar from '@mui/material/AppBar';
import CssBaseline from "@mui/material/CssBaseline";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import { styled, alpha  } from '@mui/material/styles';
import {Chip} from "@mui/material";
import Box from "@mui/material/Box";
import authService from '../api-authorization/AuthorizeService'
import RandomQuote from "../randomQuote";
import {HelpText} from "../HelpText";
import SimpleBackdrop from "../backdrop";
import moment from 'moment';
import IssueList from "../issues/issuelist";
import Title from "../Title";
import Stack from "@mui/material/Stack";
import {Update} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EditIcon from '@mui/icons-material/Edit';
import StyledActionButton from "../GreenButton";
import FormDialog from "./formdialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Alert from '@mui/material/Alert';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import MoneyIcon from '@mui/icons-material/Money';
import {link} from "@uiw/react-md-editor";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {StyledMenu} from "../styledMenu";
export default function ProjectDetails({updateTitle}) {

    const [ project, setProject] = React.useState({keywords:[]});
    const [reportDetails, setReportDetails] = React.useState({});

    const [ reports, setReports] = React.useState([]);
    const [ reviewedReports, setReviewedReports] = React.useState([]);
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [active, setActive] = React.useState(false);
    const [drawOpen, setdrawOpen] = React.useState(false);
    
    const fetchData = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/details/' + id + '/report', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setReports(data);
        setLoading(false);
    }
    const fetchReviewedData = async () => {
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/details/' + id + '/report/reviewed', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setReviewedReports(data);
    }
    const fetchProjectData = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/details/' + id , {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        updateTitle('Project '+data.id+': ' + data.name);
        setProject(data);
    }
    
    useEffect(() => {

        console.log('use effect');
        fetchData()
            // make sure to catch any error
            .catch(console.error);
        fetchProjectData()
            // make sure to catch any error
            .catch(console.error);
        fetchReviewedData()
            // make sure to catch any error
            .catch(console.error);
    }, [id])
    
const getUniqueOrganisationBrands = (urls) => {
    const uniqueBrands = new Set();

    let linksFound = false
    urls.forEach(urlObj => {
        if (urlObj.brands && Array.isArray(urlObj.brands)) {
            urlObj.brands.forEach(brand => {
                if (brand.organisation_brand && brand.organisation_brand.name) {
                    uniqueBrands.add(brand.organisation_brand.name);
                    if(!linksFound){
                        if(brand.redirects === true || brand.dest_page === true || brand.link === true){
                            linksFound = true
                        }
                    }
                }
            });
        }
    });

    return {brands: Array.from(uniqueBrands), linksFound: linksFound}
};
   
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setdrawOpen(open);
    };
    const updateDetails = (v) => {
        console.log(v);
        //debugger;
        updateTitle('Project');
        toggleDrawer(true);
     
        setReportDetails(v);
        setdrawOpen(true);
       

        fetch('/api/projects/details/' + id + "/report/viewed/" + v.report_entry_id
            , {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        }).catch(console.log);
        v.viewed = true;
    };

    const styles = {
        navBar: {'top': AppBar.height}
    }
    const drawerWidth = "30em";

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    }));

    const noEntries = () => {
        return (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Box sx={{my:{xs:3, md:5}}}>
                    <Typography variant={"h5"} align={"center"}>Nothing here! </Typography>
                    <Typography align={"center"} sx={{mx:10, mt:5}}>This project has no content needing a review right now, come back later.</Typography>
                </Box>
            </Paper>
        )
    }

   
    const editCallback = () => {
        fetchProjectData().catch(console.log).finally(() => setLoading(false))
        setHandleEdit(false);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [handleEditMode, setHandleEdit] = React.useState(false);
    const handleEdit = () => {
        setAnchorEl(null);
        setHandleEdit(true);
    };

    const [handleArchiveMode, setArchiveMode] = React.useState(false);
    
    const handleArchive = () => {
        setAnchorEl(null);
        setArchiveMode(true);
    };
    const handleArchiveClose = () => {
        setAnchorEl(null);
        setArchiveMode(false);
        
    };
    
    const confirmDoArchive = async () => {
      console.log('archive');
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/' + project.id + '/archive', {
            method: "PUT"
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        editCallback();
        setLoading(false);
        setAnchorEl(null);
        setArchiveMode(false);
    };
    const entriesTable = (reports2) => {
        return (
            <TableContainer component={Paper}>
                {(reports2 && reports2.length > 0) && (<Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width:"20px"}}></TableCell>
                            <TableCell>Video</TableCell>
                            <TableCell>Channel</TableCell>
                            <TableCell>Platform</TableCell>
                            <TableCell sx={{minWidth:170}} >Date</TableCell>
                            {/*<TableCell >Report Entires</TableCell>
                                <TableCell >Views</TableCell>
                                <TableCell >Duration (s)</TableCell>
                                <TableCell>Labels</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reports2.map((row) => {
                            let brandsInContent = row.brand_matches && row.brand_matches.length > 0;
                            let linksInContent = row.brand_matches && row.brand_matches.filter(e=>e.links_redirects === true || 
                            e.links_dest_links === true || e.links_url === true).length > 0;
                            let visualInContent = row.brand_matches && row.brand_matches.filter(e=>e.visual_matches === true).length > 0;
                           return (
                            <TableRow
                                hover
                                key={row.report_entry_id}
                                onClick={(event) =>updateDetails(row)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                            >
                                <TableCell>
                                    {(row.media.channel.videos[0].aff_evidence_exists || brandsInContent || linksInContent || visualInContent) && (

                                        <BusinessCenterIcon color={brandsInContent && linksInContent && visualInContent ? "error" : "warning"} sx={{opacity:0.7}}/>

                                    )}
                                </TableCell>
                                <TableCell scope="row"
                                           style={{fontWeight: (!row.viewed || row.viewed === false) ? "bold" : "normal"}}
                                >
                                        
                                        <span>{row.media.video_title}</span>
                                
                                </TableCell>

                                <TableCell>{row.media.channel.handle}</TableCell>
                                <TableCell>{row.media.channel.channel_type}</TableCell>
                                <TableCell>{moment(row.date_created).format('DD MMM, HH:mm')}</TableCell>
                                {/*<TableCell align="right">{row.report_entries.length}</TableCell>
                                    <TableCell align="right">{row.media.views}</TableCell>
                                    <TableCell align="right">{row.media.duration}</TableCell>
                                    <TableCell>{row.visual_labels.length}</TableCell>*/}
                            </TableRow>
                        )})}
                    </TableBody>
                </Table>)}
            </TableContainer>        
        )
    }
    return (
        <div>
            {handleEditMode && <FormDialog existing={project} cb={editCallback } />}
            <Stack component="div" alignItems={"center"}>
                <StyledActionButton
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    sx={{fontSize:14}}
                >
                    Options
                </StyledActionButton>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleEdit} disableRipple>
                        <EditIcon />
                        Edit
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={handleArchive} disableRipple>
                        <ArchiveIcon />
                        Archive
                    </MenuItem>
                </StyledMenu>
               {project.project_status === 1 && (
                    <Stack sx={{pt:2}} alignItems={"center"}>
                        <Alert severity="warning">
                        <Typography>This project is archived.</Typography>
                        <Typography sx={{fontSize:12}}>{project.archived_by.display_name} archived it on {moment(project.date_project_archived).format('llll')}</Typography>
                        </Alert>
                    </Stack>
                )}
            </Stack>
            {project.keywords && project.keywords.length > 0 && (<Box component="div">
                <Title>Project keywords</Title>
                <Stack direction={"row"} spacing={1} flexWrap="wrap">
                    {project.keywords.map((row) => (
                        <Chip label={row} key={row} sx={{mb:2}} />
                    ))}
                </Stack>
            </Box>)}

            {project.open_issues && project.open_issues.length > 0 && (
                <Box component="div" sx={{mt: 3, mb: 2}}>
                    <Title>Issues related to this project</Title>
                    <IssueList issues={project.open_issues} showadvanced={true} />
                </Box>
            )}
            
            <Grid
                container
                direction="row"
                sx={{mt: 3, mb: 2}}
            >
                <Title>Unreviewed project entries</Title>

                {(!loading && (!reports || reports.length === 0)) && (noEntries())}
                
                {(!loading && (reports && reports.length > 0)) && entriesTable(reports)}
                

            </Grid>

            <Grid
                container
                direction="column"
                sx={{mt: 3, mb: 2}}
            >


                    
                        <Title>Reviewed project entries</Title>
                        <Box><Typography>Up to the last 15 items which are reviewed and resolved.</Typography></Box>
                        {(!loading && (reviewedReports && reviewedReports.length > 0)) && entriesTable(reviewedReports)}
                <Stack direction={"column"} spacing={1} w2></Stack>
         
            </Grid>
            
            <Fragment>
            <CssBaseline />
            <Drawer

                sx={{
                    zIndex: 1000,
                    width: {xs:'100%', md:"65%"},
                    [`& .MuiDrawer-paper`]: {
                        width: {xs:'100%', md:"65%"},
                    },
                }}
                anchor='right'
                open={drawOpen} containerStyle={styles.navBar}
                onClose={toggleDrawer(false)}
            >
                <DrawerHeader>
                    <IconButton onClick={toggleDrawer(false)}>
                        <ChevronLeftIcon />  
                    </IconButton>
                    <strong>Video Review: &nbsp;</strong>
                    {project.name}
                </DrawerHeader>
                <Typography sx={{p:1, fontSize:16}} component="h6" variant="h6" >{reportDetails.title_at_report}</Typography>
                <Divider />
                <ReportEntry report={reportDetails} project={project} />
            </Drawer>
                </Fragment>

            <HelpText>
                The entities show above have matched in a search for a variable in the project keyword. Most likely, a 
                keyword. The entities shown above have not yet been reviewed and marked as having an issue or no issue.
                
                You can click a different button above to show entities which have been previously reviewed.

                Projects <strong>in bold</strong> are viewed by you or somebody in your team, but not actioned.
                <Divider sx={{my:3}}/>
                <Stack spacing={2}>
                    <Stack direction={"row"} spacing={2}>
                        <BusinessCenterIcon color={"error"} sx={{opacity:0.7}}/>
                        <Box>Video contains a keyword, a link and a logo or visual asset. These are your most significant items.</Box>
                    </Stack>
                   <Stack direction={"row"}  spacing={2}>
                       <BusinessCenterIcon color={"warning"} sx={{opacity:0.7}}/>
                       <Box>Video contains a keyword and either a link or a logo or visual asset. These are using your brand in some ways.</Box>
                   </Stack>
                </Stack>
            </HelpText>

            <Dialog
                open={handleArchiveMode}
                onClose={handleArchiveClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Archive this project?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Archiving a project retains its data but does not delete it. You can unarchive a project at any time but while it is archived, it will not be updated.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleArchiveClose} sx={{color:'#279100'}}>
                        No, and cancel
                    </Button>
                    <Button onClick={confirmDoArchive} sx={{color:'red'}}>
                        Yes, archive this project
                    </Button>
                </DialogActions>
            </Dialog>
            
            <SimpleBackdrop  open={loading}/>
        </div>
    );
}
