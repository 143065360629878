import {Backdrop} from "@mui/material";
import React, { Component, useCallback, useState, useEffect } from 'react';

import CircularProgress from "@mui/material/CircularProgress";

export default function SimpleBackdrop({open}) {

    const handleClose = () => {

    };


    return (
        <div>
           
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
