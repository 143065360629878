import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import ResponsiveAppBar from "./NavBarMain";
import authService from "./api-authorization/AuthorizeService";
import {useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import moment from "moment";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {blue, lightBlue, yellow} from "@mui/material/colors";

export default function Dashboard(props) {
    const { classes } = props;
    const [user, setUser] = React.useState(null);
    

    useEffect(()=> {
        authService.getUser().then((user) => {
            setUser(user)
            console.log(user);
        })
        
    },[])

    const theme = createTheme({
        palette: {
            primary: {
                main: '#b8f2ff', // Customize AppBar color
            },
            // Add other color customizations here
        },
        components: {
            MuiLink: {
                styleOverrides: {
                    root: { // Apply styles to the root element
                        color: '#318091', // Default color
                        fontWeight: '700',
                        '&:hover': {
                            color: '#0056b3', // Color on hover
                        },
                    },
                },
            },
        },
    });
    
  const renderDash = () => {

  return (

      <Box>

          <ResponsiveAppBar />
          

          <Container maxWidth="lg" sx={{mt:0}}>


              {props.title && props.title != "" && (
              <Typography variant="h5" component="div" sx={{ marginBottom: '0.5em' }} align={'center'}>
                  {props.title ?? "Digitals"}
              </Typography>
              )}
              
              {props.children}
          </Container>

          {false && user != null && user.organisation.account_type == 1 &&  (
              <ThemeProvider theme={theme}>
                  <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, p:0, textAlign: 'center', fontSize: '0.9em'}} shadd>
                      {moment(user.organisation.account_expiry).isAfter(moment()) && <Box>
                          <Typography variant={"p"} align={"center"} sx={{color:'#318091'}}>Hey {user.first_name}, you're on a trial. It expires in {moment(user.organisation.account_expiry).from(moment())}.
                          </Typography> 
                          <Link  component={RouterLink} to={'/billing'} sx={{mx:1}}>Ready to commit?</Link> 
                          <Typography variant={"p"} align={"center"} sx={{color:'#318091'}}>If you need any help, shoot us an email support@trackwithdigitals.com
                          </Typography>
                      </Box>}
                  </AppBar>
              </ThemeProvider>
          )}
      </Box>

  )}
    
    return (
        <div>
            {renderDash()}
        </div>
    )
}
