import React, { Component, useCallback, useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import authService from './api-authorization/AuthorizeService'

 
import moment from "moment/moment";
import IssueEvent from "./issues/issueEvent";
import DashboardEvent from "./dashboard/DashboardEvent";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import {Link} from "react-router-dom";
import FormDialog from "./project/formdialog";
import DragDropInput from "./drag";
export default function Main({updateTitle}) {

    const [dashboardData, setDashboard] = React.useState({feed: [],videos:[]});
    const [projects, setProjects] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [groupedEvents, setGroupedEvents] = React.useState([]);

    const fetchProjects = async () => {
  
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setProjects(data);

    }
    useEffect(() => {
        // declare the async data fetching function
        updateTitle('Digitals');
        fetchData()
            // make sure to catch any error
            .catch(console.error);
        
        fetchProjects().catch(console.error);
    }, [])

    const fetchData = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/dashboard', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        setLoading(false);
        const data = await response.json();

        setDashboard(data);
        setisToday(moment(data.feed[0].event_date).isSame(moment(), 'day'));
        setGroupedEvents(groupEventsByKey(data.feed));
        
       
        
    }

    const groupEventsByKey = (events) => {
        return events.reduce((groups, event) => {
            const key = event.key; // Use the 'key' property to group
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(event);
            return groups;
        }, {});
    };
    const [isToday, setisToday] = React.useState(true);
    let afterTodayInserted = false; // Flag to track if the title has been inserted

    const Loading = () => {
        return (
            <Box sx={{my:5}}>
                <Typography variant={"h5"} align={"center"}>Loading your feed...</Typography>
            </Box>
        )
    }
    
    const refresh = () => {
        fetchProjects().catch(console.error);
    }
    const noProjects = () => {
        return (
            <Box sx={{my:5}}>
                

                {authService.isInRole(["ADMIN", "CREATEPROJECT"]) && (

                    <Grid>
                        <Typography variant={"h5"} align={"center"}>Create your first project</Typography>
                        <Typography align={"center"} sx={{mx:10, mt:5}}>Get started and create your first project. Projects are used to group and control the information that you are looking for. </Typography>

                        <FormDialog items={[]} cb={refresh} />
                    </Grid>
                )}

                {!authService.isInRole(["ADMIN", "CREATEPROJECT"]) && (

                    <Grid>
                        <Typography variant={"h5"} align={"center"}>No projects setup yet</Typography>
                        <Typography align={"center"} sx={{mx:10, mt:5}}>Ask your team admin to setup a project. </Typography>
                    </Grid>
                )}
            </Box>
        )
    }
    
    return (
        <div>
            <Grid>
                
                <Typography align="center" sx={{mb:1,fontSize:15}}>
                    Recent projects below. <Link tag={Button}  to={'/projects/'}>
                    Full List
                </Link>
                </Typography>
                <Grid container spacing={2}  direction="row"
                      justifyContent="center"
                      alignItems="center">
                    
                    {projects.map((project) => (
                        <Grid item xs={6} md={3}>
                            <Card elevation={1} key={project.id}  sx={{ p:{xs:0,md:0}}}>

                                <CardContent sx={{height: 175, p:{xs:0.5,md:2}}}>

                                        <Link tag={Button}  to={'/projects/' + project.id}>
                                            <Typography component="h5" variant="h6" align={'center'}>{project.name}</Typography>
                                        </Link>
                                    <Typography sx={{mt:2, fontSize:30, fontWeight:'bold'}} align="center">{project.pending_items}</Typography>
                                    <Typography sx={{mt:0, fontSize:20}} align="center">pending videos</Typography>

                                    
                                </CardContent>
                                <CardActions>
                                    <Typography align="center"  sx={{color:'#c0c0c0', fontSize: 14}}>updated {moment(project.date_last_entry).fromNow()}</Typography>


                                </CardActions>
                            </Card>
                        </Grid>
                    ))}

                    {projects.length == 0 && noProjects()}
                </Grid>
                
                <Grid container spacing={2} justifyContent={"center"}>

                    <Grid item xs={12} md={8}>
                            <Divider sx={{my: 5}}>FEED</Divider>
                            <Stack spacing={2}>
                                {loading && (Loading())}

                                {Object.keys(groupedEvents).map((key) => {
                                    let content = (
                                        <Paper sx={{p: 1}}>
                                            <DashboardEvent key={key} rows={groupedEvents[key]} />
                                        </Paper>);
                                    if(!afterTodayInserted && isToday && !moment(groupedEvents[key][0].event_date).isSame(moment(), 'day')){
                                        afterTodayInserted = true;
                                        return (
                                            <React.Fragment key={key}>
                                                <h2>Older events</h2>
                                                {content}
                                            </React.Fragment>
                                        )
                                    }
                                    return content;

                                })}
                            </Stack>
                        </Grid>

                    <Grid item xs={12} md={4}>
                        <Divider sx={{my: 5}}>CHANNEL UPDATES</Divider>
                        <Stack spacing={2}>
                            {loading && (Loading())}
                            <Stack spacing={2}>
                                {!loading && dashboardData.videos.map((e) => 
                                     (
                                        <Box key={e.video.id}>
                                            <Typography>{e.video.channel.handle}</Typography>
                                            <Typography>{e.video.video_title}</Typography>
                                            <Typography>{moment(e.video.date_received).fromNow()}</Typography>
                                        </Box>
                                    )
                                )}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

            </Grid>
        </div>
    );

}
