import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MuiChipsInput } from 'mui-chips-input'
import {FormControl, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import YouTubeIcon from '@mui/icons-material/YouTube';
import authService from '../api-authorization/AuthorizeService'
import StyledActionButton from "../GreenButton";
export default function FormDialog({cb}) {

    const [open, setOpen] = React.useState(false);
    const [channelType, setChannelType] = React.useState("YouTube");
    const [url, setUrl] = React.useState("")

    
    const handleClickOpen = async () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleChange = (newChips) => {
        setUrl(newChips.target.value)
    }
    const handleSelectChange = (e) => {
        setChannelType(e.target.value)
    }
   
    const create = async (event) => { 


        var req = {
            type: channelType,
            channelurl: url
        }
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/channels?2', {
            method: "POST",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setOpen(false);
        cb();
    }
    
    return (
        <div>
            <StyledActionButton onClick={handleClickOpen}>
                Create 
            </StyledActionButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Subscribe to Channel</DialogTitle>
                <DialogContent>

                   
                        <Select fullWidth
                            value={channelType}
                            label="Channel Source"
                            onChange={handleSelectChange}
                        >
                            <MenuItem value={"YouTube"}>YouTube</MenuItem>
                            <MenuItem value={"Twitch"}>Twitch</MenuItem>
                            {/* <MenuItem value={"TelegramPublic"}>Telegram (Public Channel)</MenuItem>*/}
                        </Select>
                    
                    <DialogContentText className="mt-3">
                        Enter the URL for the channel
                    </DialogContentText>
                    
                    <TextField margin="dense" label="URL" variant="standard" fullWidth={true} value={url} onChange={handleChange} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={create}>Create</Button>
                </DialogActions>
            </Dialog>
            
        </div>
    );
}