import * as React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {useEffect} from "react";
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import authService from './api-authorization/AuthorizeService'
import moment from 'moment';
export default function VideoDetailsCard({
                                             open,
                                             handleOpenDialog,
                                             handleCloseDialog,
                                             columns,
                                             row,
                                                channelId,
                                             active
                                         }) {

    const [loading, setLoading] = React.useState(false);
    const [ video, setVideo] = React.useState(row);

    useEffect(() => {
        
        if(row && row.id) {
            // declare the async data fetching function
            setVideo(row);


            const fetchData = async () => {
                setLoading(true);
                //const token = await authService.getAccessToken();
                const response = await fetch(`/api/channels/details/${channelId}/video/` + row.id, {
                    //headers: !token ? {} : {'Authorization': `Bearer ${token}`}
                });
                authService.followIfLoginRedirect(response);
                const data = await response.json();
                setVideo(data);
                //console.log("video detais loads");
                setLoading(false);
            }

            // call the function
            fetchData()
                // make sure to catch any error
                .catch(console.error);
            ;
        }
    }, [row,channelId])
    const handleClose = () => {
        handleCloseDialog();
    };
    const handleClickOpen = () => {
        handleOpenDialog();
    };
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            fullWidth
        >
            
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {video.video_title}
                    </Typography>
                    
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
               <strong>Video Id:</strong>  {video.video_id } <br />
                {video.date_received ? <span><strong>Video Received:</strong>  {moment(video.date_received).format("dddd, MMMM Do YYYY, h:mm:ss a") } <br /></span> : <span></span>}
                {video.last_updated ? <span><strong>Last Updated:</strong>  {moment(video.last_updated).format("dddd, MMMM Do YYYY, h:mm:ss a") } <br /></span> : <span></span>}


                <h3>Description</h3>
                {video.video_description ? video.video_description.split('\n').map(function(item) {
                    return (
                <span>{item}<br/></span>
                    )
                }) : (<span>"No Description"</span>)}
            </DialogContent>
        </Dialog>
        // </div>
       
    );
}