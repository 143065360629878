import * as React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as PropTypes from "prop-types";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import MDEditor, { commands } from '@uiw/react-md-editor';
import authService from '../api-authorization/AuthorizeService'
PersonAddIcon.propTypes = {fontSize: PropTypes.string};
export default function AddIssueUpdate({issue, cb}) {
    const editor = useEditor({
        extensions: [StarterKit],
        content: "",
    });
    const [value, setValue] = React.useState("");

    const postComment = async () => {


        var req = {
            comment: value
        }
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/comment/'+issue.id+'', {
            method: "POST",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req) 
        });
        authService.followIfLoginRedirect(response);
        console.log(response);
        if(response.status === 204){
            setValue("");
            cb()
        }else{
            console.log('error posting comment');
        }
    }
    
    function updateIssueView(){
        return (
            <Card>
                <CardContent>
                    <MDEditor
                        value={value}
                        onChange={setValue}
                        preview="edit"
                        commands={[
                            commands.codeEdit, commands.codePreview,
                            commands.bold, commands.code, commands.quote,
                            commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6
                        ]}
                       
                    />
                    <Button onClick={() => {postComment();console.log(value)}}>
                        Add Comment
                    </Button>

                    <div>
                        {value && value !== "" && (
                        <div>
                            <Typography variant="h5" component="h5"  sx={{ mt: 3,mb: 2}}>
                                Preview
                            </Typography>
                            <MDEditor.Markdown source={value} />
                        </div>
                        )}
                    </div>
                </CardContent>
            </Card>
        )
    }
    function issueIsClosed(){
        return (<div>
            The issue is closed, you cannot add any further updates.
        </div>)
    }

    let issueView = issueIsClosed();
    if(issue.status < 100){
        issueView = updateIssueView();
    }


    return (
        <div>
            {issueView}
        </div>
    );
}