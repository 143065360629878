import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import authService from '../api-authorization/AuthorizeService'
import {Checkbox, FormControlLabel} from "@mui/material";
import Typography from "@mui/material/Typography";
import StyledActionButton from "../GreenButton";
export default function CreateMember({cb}) {

    const [ items, setItems] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [roles, setRoles] = React.useState([]);
    
    const allRoles = [
        {name:"admin", display: "Admin - Do Everything"}, 
        {name:"createproject", display: "Projects - Basic + Create Projects" },
        {name:"basic", display: "Basic - manage videos/issues"}
    ]
    const handleClickOpen = async () => {


        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const create = async (event) => {
        //const newItems = items;
        //newItems.push({name: projectName})
        //setItems(newItems);


        var req = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            roles: roles
        }
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/Organisation', {
            method: "POST",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        //this.setState({ items: data, loading: false });

        setFirstName("");
        setLastName("");
        setOpen(false);
        cb();
        
    }
    const handleChange = (event) => {
        console.log(roles);
        let newRoles = [event.target.name, ...roles];
        if(!event.target.checked){
            newRoles = newRoles.filter(e => e !== event.target.name);
        }
        setRoles(newRoles.filter((value, index, array) => array.indexOf(value) === index));
        
        console.log(newRoles)
    };
    return (
        <div>
            <StyledActionButton onClick={handleClickOpen}>
                Create
            </StyledActionButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create Member</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                       Add member to your team
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="First Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={firstName}
                        onChange={handleFirstNameChange}
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Last Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={lastName}
                        onChange={handleLastNameChange}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    
                    
                    <DialogContentText>
                        Their password will be emailed to them
                    </DialogContentText>

                    <Typography variant="h5" component="h5"  sx={{ mt: 3,mb: 2}}>
                        Roles
                    </Typography>
                    {allRoles.map((column) => (
                        <FormControlLabel key={"1"+ column.name}
                            control={
                        <Checkbox label={column.display} onChange={handleChange} name={column.name} />
                            } label={column.display} key={"2"+ column.name}/>
                    ))}
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={create}>Create</Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}