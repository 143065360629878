import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useForm } from "react-hook-form"
import {FormContainer, PasswordElement, TextFieldElement} from "react-hook-form-mui";
import {useEffect} from "react";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

// TODO remove, this demo shouldn't need to reset the theme.

const theme = createTheme({
    typography: {
        "fontFamily": `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
        //`"Roboto", "Arial", "Helvetica", "Arial", sans-serif`,
        "fontSize": 26,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    palette: {
        /*primary: {
            light: '#6F6058',
            main: '#3B3435',
            dark: '#241F20',
            contrastText: '#fff',
        },
        secondary: {
            light: '#FF7E43',
            main: '#221e1f',
            dark: '#221e1f',
            contrastText: '#000',
        },
        action: {
            active: '#FF641D',
            hover: '#6F6058',
            focus: '#FF641D'
        }
        
         */
        type: 'dark',
        primary: {
            main: '#FF641D',
        },
        secondary: {
            main: '#221E1F',
        },
        action: {
            active: '#FF641D',
            hover: '#6F6058',
            focus: '#FF641D'
        }
    },

    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "rgb(59, 52, 53)",
                    color: "#FFF"
                }
            }
        }
    }
});
export default function Signup1({data, callback}) {

    const { register, handleSubmit } = useForm();

    useEffect(() => {

    }, [])
    
    
    const onSubmit = (event) => {

        console.log(event);
        callback(event)
    };

    return (
      
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1 }}>
                        <PersonAddIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <Box  sx={{ mt: 3 }}>
                        <FormContainer
                            defaultValues={data}
                            onSuccess={(data)=>{
                                onSubmit(data);
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextFieldElement name="first_name" label="FirstName" required />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextFieldElement name="last_name" label="Last Name" required />
                                </Grid>

                                <Grid item xs={12}>
                                    <PasswordElement
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                    />
                                </Grid>
                            </Grid>
                            <p>Clicking continue signals that you have read and agreed to the <a href={"#"}>Terms</a>.</p>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 0, mb: 2 }}
                            >
                                Continue
                            </Button>
                        </FormContainer>
                    </Box>
                </Box>
            </Container>

    );
}