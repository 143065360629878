import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from "@mui/material/Grid";
import * as PropTypes from "prop-types";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MDEditor from '@uiw/react-md-editor';
import authService from '../api-authorization/AuthorizeService'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IssueEvent from "../issues/issueEvent";
import {Link} from "react-router-dom";
PersonAddIcon.propTypes = {fontSize: PropTypes.string};
export default function DashboardEvent({rows}) {



    function getPreUpdateRender(row){
        if(row.project_entry.media_type ==0){
            return (
                <span>
                    <strong>Video:</strong> <span>{row.project_entry.media.video_title}</span>
                </span>
            )
        }
    }

    
    function channelVideo(row){
        return (
            <div>
                Video
            </div>
        )
    }
    function projectReportEntry(row){
        return (
            <div>
   
               Project <Link to={`/issues/${rows[0].project.id}`} sx={{fontWeight:700}}>{row.project.name}</Link> was updated.
                <div>{getPreUpdateRender(row)}</div>
            </div>
        )
    }
    function issueEvent(){
        return (
            <div>
                Issue <Link to={`/issues/${rows[0].issue.id}`} >{rows[0].issue.id}</Link> was updated.
                {rows.map((row) => (
                    <IssueEvent issueEvent={row.issue_event} issue={row.issue} cb={()=>{}} showDate={false} hideControls={true} />
                ))}
            </div>
        )
    }

    let event;
    let icon;
    let isDate = true;
    switch(rows[0].event_type){
        case 0: //Comment
            event = channelVideo(rows[0]);
            isDate = false;
            break;
        case 1:
            event = projectReportEntry(rows[0]);
            icon = <PersonAddIcon fontSize='medium' />;
            break;
        case 2:
            event = issueEvent(rows[0])
            icon = <PersonAddIcon fontSize='medium' />;
            break;
    }

    function layoutWithIcon(){
        return (
            <Grid  container direction="row" alignItems="center">
                <Grid item xs={0.5}  alignItems="center">
                    {icon}
                </Grid>
                <Grid item xs={11}>
                    {event}
                </Grid>
            </Grid>
        )
    }

    function layoutWithoutIcon(){
        return (
            <Grid container>
                <Grid item xs={12}>
                    {event}
                </Grid>
            </Grid>
        )
    }
    let layout
    if(icon){
        layout = layoutWithIcon()
    }else{
        layout = layoutWithoutIcon()
    }

    let date = moment(rows[0].event_date);
    let dateDisplay = (
        <Typography sx={{ fontSize: 14, fontStyle: 'italic' }} gutterBottom>
            {date.format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
    );

    if(date.isAfter(moment().subtract(1, 'hours'))) {
        dateDisplay = (
            <Typography sx={{ fontSize: 14, fontStyle: 'italic' }} gutterBottom>
                {date.format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
        );
    }

    if(date.isSame(new Date(), "day")){
        dateDisplay = (
            <Typography sx={{ fontSize: 14, fontStyle: 'italic' }} gutterBottom>
                {date.format('h:mm:ss a')}
            </Typography>
        );
    }


    return (
        <Grid item sx={{ mt: 0,mb: 0}}>
            {isDate && (dateDisplay)}
            {layout}
        </Grid>
    );
}