import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import { fabric } from 'fabric';
import {addRect, getRandomColor, setUpRect} from './canvas-functions';
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import authService from "../api-authorization/AuthorizeService";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Fab, FormControl, ListItem, ListItemAvatar, Radio, RadioGroup, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import {MuiColorInput} from "mui-color-input";
import Button from "@mui/material/Button";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from '@mui/icons-material/Folder';
import Avatar from "@mui/material/Avatar";
import AddLabelComponent from "./addLabelComponent";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const ImagePolygonDrawer = ({ img, imageUrl, image_id,onSave, brand_id}) => {


    //const [isSpacePressed, setIsSpacePressed] = useState(false);


    /*(const handleKeyDown = (e) => {
        if (e.code === "Space") {
            setIsSpacePressed(true);
        }
    };

    const handleKeyUp = (e) => {
        if (e.code === "Space") {
            setIsSpacePressed(false);
        }
    };*/
    const [loading, setLoading] = React.useState(true);

    //window.addEventListener('keydown', handleKeyDown);
    //window.addEventListener('keyup', handleKeyUp);
    const getRandomByte = () => Math.floor(Math.random() * 256);
    const [labels, setLabels] = useState([
        //{"id":3, "name":"Logo 1", "c": `rgba(${getRandomByte()}, ${getRandomByte()}, ${getRandomByte()}, 0.6)`},
        //{"id":4, "name":"Logo 2", "c": `rgba(${getRandomByte()}, ${getRandomByte()}, ${getRandomByte()}, 0.6)`},
        //{"id":5, "name":"Logo 3","c": `rgba(${getRandomByte()}, ${getRandomByte()}, ${getRandomByte()}, 0.6)`},
    ]);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [rects, setRects] = useState([]);
    
    const [currentCanvasObject, setCurrentCanvasObject] = useState(null);

    const canvasRef = useRef(null);
    const fabricRef = useRef(null);
    const rectsRef = useRef(); // Create a reference to the rects array



    const [labelName, setLabelName] = useState("");
    const handleNameChange = (e) => {
        //setLabelName(e.target.value)
    };

    let isPanning = false;
    let lastPosX = 0;
    let lastPosY = 0;
    
    const makeSetup = () => {

        if (fabricRef.current) {
            fabricRef.current.dispose();
        }

        
        fabricRef.current = new fabric.Canvas(canvasRef.current, {
            height: 624,
            width: 1000,
            backgroundColor: '#c0c0c0',
        });
        fabricRef.current.uniformScaling = false;
        fabricRef.current.selection = false;


        fabricRef.current.on('mouse:down', function(opt) {
            var e = opt.e;
            // Check if an object is active (selected)
            if (fabricRef.current.getActiveObject() == null) {
                // If no object is active, enable panning
                isPanning = true;
                lastPosX = e.clientX;
                lastPosY = e.clientY;
            }
        });

        fabricRef.current.on('mouse:up', function() {
            isPanning = false;
        });

        fabricRef.current.on('mouse:move', function(opt) {
            if (isPanning && opt && opt.e) {
                var e = opt.e;
                var vpt = fabricRef.current.viewportTransform;
                vpt[4] += e.clientX - lastPosX;
                vpt[5] += e.clientY - lastPosY;
                fabricRef.current.setViewportTransform(vpt);
                lastPosX = e.clientX;
                lastPosY = e.clientY;
            }
        });
        
        fabricRef.current.on('mouse:wheel', function(opt) {
            var delta = opt.e.deltaY;
            var zoom = fabricRef.current.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 0.01) zoom = 0.01;
            fabricRef.current.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
        });
        fabricRef.current.on('object:modified', () => {
            setCurrentCanvasObject(fabricRef.current.toObject().objects)
        });

       
        fabricRef.current.on('selection:created', function(e) {
            
            if(e.selected.length == 1 && (e.selected.type == 'rect' || e.selected.type == 'group')) {
                return;
            }
            var id = e.selected[0].id;
            var updatedRects = rectsRef.current.map((r) => {
                // Reset the selected property for all rectangles
                r.selected = false;
                // If the rectangle id matches the selected id, set selected to true
                if (r.id === id) {
                    r.selected = true;
                    console.log({id, r});
                }
                return r;
            });
            // Update the rects state with the new array
            setRects(updatedRects);
        });
        fabricRef.current.on('selection:cleared', function() {
            // Iterate over the rects array
            const updatedRects = rectsRef.current.map((r) => {
                // Set the selected property of each rectangle to false
                r.selected = false;
                return r;
            });

            // Update the rects state with the new array
            setRects(updatedRects);
        });

        fabricRef.current.on('selection:updated', function(e) {
            if(e.selected.length == 1 && (e.selected.type == 'rect' || e.selected.type == 'group')) {
                return;
            }
            var id = e.selected[0].id;
            var updatedRects = rectsRef.current.map((r) => {
                // Reset the selected property for all rectangles
                r.selected = false;
                // If the rectangle id matches the selected id, set selected to true
                if (r.id === id) {
                    r.selected = true;
                    console.log({id, r});
                }
                return r;
            });
            // Update the rects state with the new array
            setRects(updatedRects);
        });
        
        fabric.Image.fromURL(imageUrl, function(img) {
            fabricRef.current.setBackgroundImage(img, fabricRef.current.renderAll.bind(fabricRef.current), {
                left: (fabricRef.current.width/2)-(img.width/2),
                top: (fabricRef.current.height/2)-(img.height/2)
            });
            //canvas.add(img.set({ left: (canvas.width/2)-(img.width/2), top: (canvas.height/2)-(img.height/2)}));
        });

        
        setCurrentCanvasObject(fabricRef.current.toObject().objects);


        return () => {
            if (fabricRef.current) {
                fabricRef.current.destroy();
            }
        };
    };

    const fetchLabels = () => {

        //const token = await authService.getAccessToken();
        fetch('/api/organisation/labels/' + brand_id, {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        }).then((response)=> {
            authService.followIfLoginRedirect(response);
            return response.json();
        }).then(data => {
            setLabels(data);
            console.log('Data received:', data);
            setSelectedLabel(data[0].id);

            const saved = localStorage.getItem("last_used_label");
            if(saved){
                setSelectedLabel(saved);
            }
            loadRects(data);
        });

    }

    const loadRects = (allLabels) =>{
        console.log('load rects');
        fetch('/api/organisation/labels/img/' + image_id + "/annotations", {
            method: "GET",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
        }).then((response)=> {
            return response.json()
        }).then((r)=>{
            var rects = r.map((r1, index)=>{

                const canvasWidth = fabricRef.current.width;
                const canvasHeight = fabricRef.current.height;
                
                //const imageTop = fabricRef.current.backgroundImage.top;
                //const imageLeft = fabricRef.current.backgroundImage.left;

                // Assuming you have the image dimensions
                const imageWidth = img.width; // Replace with your image width
                const imageHeight = img.height; // Replace with your image height
                
                // Calculate the center coordinates of the canvas
                const centerX = canvasWidth / 2;
                const centerY = canvasHeight / 2;
                
                // Calculate the top-left corner coordinates of the image
                const imageX = centerX - (imageWidth / 2);
                const imageY = centerY - (imageHeight / 2);
                
                let label = allLabels.filter(f=>f.id == r1.label_id)[0];
                
                let width = r1.right-r1.left;
                let height = (r1.bottom-r1.top);
                let left = r1.left + imageX;
                let top = r1.top + imageY;
                
                
                const testRect = new fabric.Rect({
                    //id: label.id + "-" + index,
                    
                    fill: 'rgba(' + label.color_hex + ', 0.4)',
                    height: height,
                    width: width,
                    stroke: 'rgba(' + label.color_hex + ', 1)',
                    strokeWidth: 2,
                    lockUniScaling: false,
                });
                //fabricRef.current.add(testRect);
                //fabricRef.current.setActiveObject(testRect);

                const text = new fabric.Text(label.name, {
                    left: testRect.left + testRect.width / 2,
                    top: testRect.top + testRect.height / 2,
                    fontSize: 15,
                    originX: 'center',
                    originY: 'center',
                    fontFamily: 'sans-serif'
                });

// Create a group that contains the rectangle and the text
                const group = new fabric.Group([testRect, text], {
                    id: label.id + "-" + index,
                    left: left,
                    top: top,
                    height: height,
                    width: width,
                    
                });
                group.on('scaling', function() {
                    console.log('scale');
                    // Set the scaleX and scaleY properties of the text to be the same as the scaleX and scaleY properties of the group
                    text.set({
                        scaleX: group.scaleX,
                        scaleY: group.scaleY
                    });
                });
                fabricRef.current.add(group);
                //fabricRef.current.setActiveObject(group);
                return {id: label.id + "-" + index, label: label, rect: group, selected: false}
            });

            setRects(rects);
            rectsRef.current = rects;
        });
        
        
    }
    
    useEffect(() => {
        makeSetup();
        fetchLabels();
    }, [img])


    const handleSelectLabel = (event) => {
        var label = labels.filter(f=>f.id == event.target.value)[0];
        console.log(label.name);
        setSelectedLabel(label.id);

    }
    const handleClick = () => {

        const canvas = fabricRef.current;
        const canvasWidth = fabricRef.current.width;
        const canvasHeight = fabricRef.current.height;
        const viewportTransform = canvas.viewportTransform;

// Get the viewport transform
        //const viewportTransform = fabricRef.current.viewportTransform;

// Calculate the center of the viewport
        //const viewportCenter = {
        //    x: -viewportTransform[4] + canvasWidth / 2,
        //    y: -viewportTransform[5] + canvasHeight / 2
        //};

        const inverseViewportTransform = fabric.util.invertTransform(viewportTransform);

        // Calculate the center of the viewport in canvas coordinates
        const viewportCenter = fabric.util.transformPoint({
            x: canvasWidth / 2,
            y: canvasHeight / 2
        }, inverseViewportTransform);

        const zoomLevel = canvas.getZoom();
        // Calculate the scaled dimensions
        const scaledWidth = 200 / zoomLevel;
        const scaledHeight = 200 / zoomLevel;
        
        var lbl = labels.filter(f=>f.id == selectedLabel)[0];
        const testRect = new fabric.Rect({
            //id: label.id + "-" + index,

            height:scaledHeight,
            width: scaledWidth,
            left: viewportCenter.x - scaledWidth / 2,
            top: viewportCenter.y - scaledHeight / 2,
            //left: viewportCenter.x-100,
            //top: viewportCenter.y-100,
            stroke: 'rgba(' + lbl .color_hex + ', 1)',
            fill: 'rgba(' + lbl .color_hex + ', 0.4)',
            strokeWidth: 2  / zoomLevel,
            lockUniScaling: false,
        });
        //fabricRef.current.add(testRect);
        //fabricRef.current.setActiveObject(testRect);

        const text = new fabric.Text(lbl .name, {
            left: testRect.left + testRect.width / 2,
            top: testRect.top + testRect.height / 2,
            fontSize: 15 / zoomLevel,
            originX: 'center',
            originY: 'center',
            fontFamily: 'sans-serif'
        });
        const originalTextScaleX = text.scaleX;
        const originalTextScaleY = text.scaleY;

// Create a group that contains the rectangle and the text
        const group = new fabric.Group([testRect, text], {
            id: lbl.id + "-" + rects.length,
            height: scaledHeight,
            width: scaledWidth,
            //left: viewportCenter.x-100,
            //top: viewportCenter.y-100,
            left: viewportCenter.x - scaledWidth / 2,
            top: viewportCenter.y - scaledHeight / 2,
        });
        
        group.on('scaling', function() {
            /*text.set({
                scaleX: originalTextScaleX,
                scaleY: originalTextScaleY
            });

            // Since the group has been scaled, reposition the text to keep it centered
            text.set({
                left: (testRect.left + testRect.getScaledWidth() / 2) - testRect.width / 2,
                top: (testRect.top + testRect.getScaledHeight() / 2) - testRect.height / 2
            });

            // Render the canvas
            fabricRef.current.requestRenderAll();*/
        });
        fabricRef.current.add(group);
        //fabricRef.current.setActiveObject(group);
        let r = rects
        r.push({id: lbl.id + "-" + rects.length, label: lbl , rect: group, selected: false});
        setRects(r)
        rectsRef.current = rects;

    };

    const handleLabelAdd= async () => {
        await fetchLabels();
    };
    
    const removeLabel = async (id) => {
// Get all objects from the canvas
        const allObjects = fabricRef.current.getObjects();

        // Find the rectangle with the matching id
        const rectToDelete = allObjects.find(obj => obj.id === id);

        setRects(rects.filter(f=>f.id !== id));
        // If a matching rectangle is found, remove it from the canvas
        if (rectToDelete) {
            fabricRef.current.remove(rectToDelete);
        }
    };

    const handleSave = async () => {
        console.log(rects);
        
        const currentZoom = fabricRef.current.getZoom();
        const viewportTransform = fabricRef.current.viewportTransform;
        const canvasState = {
            zoom: currentZoom,
            viewportTransform: viewportTransform
        };
        
        
        fabricRef.current.setZoom(1);
        fabricRef.current.renderAll();
        
        var coords = fabricRef.current.getObjects().map((obj) => {
            if (obj.type === 'group') {
                console.log(obj);
                console.log(`Rectangle id: ${obj.id}`);
                console.log(`Rectangle width: ${obj.width}`);
                console.log(`Rectangle position: (${obj.left}, ${obj.top})`);

                const imageTop = fabricRef.current.backgroundImage.top;
                const imageLeft = fabricRef.current.backgroundImage.left;
                const zoom = fabricRef.current.getZoom();

                // Get the current zoom level
                /*

                // Calculate the rectangle's coordinates relative to the image
                const relativeTop = (obj.top - imageTop);
                const relativeTopZoomAdjust = relativeTop / zoom;
                const relativeLeft = (obj.left - imageLeft);
                const relativeLeftZoomAdjust = relativeLeft / zoom;
                const width = (obj.oCoords.br.x - obj.oCoords.bl.x) / zoom;
                const height = (obj.oCoords.bl.y - obj.oCoords.tl.y) / zoom;*/

                //const imageTop = fabricRef.current.backgroundImage.top;
                //const imageLeft = fabricRef.current.backgroundImage.left;

                // Calculate the normalized top and left coordinates (as if zoom was 1)
                const canvasWidth = fabricRef.current.width;
                const canvasHeight = fabricRef.current.height;

// Calculate the center of the canvas
                const centerX = canvasWidth / 2;
                const centerY = canvasHeight / 2;

// Calculate the normalized top and left coordinates (as if zoom was 1)
// and adjust them to be relative to the center of the canvas
                const normalizedTop = (obj.top / zoom) - (imageTop / zoom);
                const normalizedLeft = (obj.left / zoom) - (imageLeft / zoom);

// Calculate the normalized width and height (as if zoom was 1)
                const normalizedWidth = (obj.width * obj.scaleX) / zoom;
                const normalizedHeight = (obj.height * obj.scaleY) / zoom;
                
                return {
                    brand_image_id: image_id,
                    label_id: parseInt(obj.id.split("-")[0]),
                    top: normalizedTop,
                    left: normalizedLeft,
                    width: normalizedWidth,
                    height: normalizedHeight,
                    right: (normalizedLeft+normalizedWidth),
                    bottom: (normalizedTop+normalizedHeight),

                }
            }
        });

        fabricRef.current.setZoom(canvasState.zoom);
        fabricRef.current.setViewportTransform(canvasState.viewportTransform);
        fabricRef.current.requestRenderAll();
        
        console.log(coords)

        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/organisation/labels/img/' + image_id + "/annotations", {
            method: "POST",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(coords)
        });
        authService.followIfLoginRedirect(response);
        setLoading(false)
        if(onSave){
            onSave(image_id);
        } 
    };
    
    const handleResetZoom = () => {
        fabricRef.current.setZoom(1);
        fabricRef.current.renderAll();
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 2 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const selectRect = (event, id) => {
        // Get all objects from the canvas
        const allObjects = fabricRef.current.getObjects();

        // Find the rectangle with the matching id
        const rectToSelect = allObjects.find(obj => obj.id === id);

        // If a matching rectangle is found, set it as the active object
        if (rectToSelect) {
            fabricRef.current.setActiveObject(rectToSelect);
            fabricRef.current.renderAll();
        }
    };
    
    return (
        <div>

            <Grid container spacing={2}>
                <Grid item xs={'auto'}>

                    <Box sx={{pt:"5px", pl:"5px"}}>
                        <canvas id="c" ref={canvasRef}/>
                    </Box>

                </Grid>
                <Grid item xs style={{ display: 'flex', flexDirection: 'column', pl: "5px" }}>

                    <Box sx={{ width: '100%', flexGrow: 1}}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Add Labels" {...a11yProps(0)} />
                                <Tab label="Added" {...a11yProps(1)} />
                                <Tab label="New" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                        
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={selectedLabel}
                                name="radio-buttons-group"
                                onChange={handleSelectLabel}    
                            >{labels.map(label => (
                                <FormControlLabel key={label.id} value={label.id} control={<Radio />}  label={label.name} />
                            ))}
                            </RadioGroup>
                            <Button onClick={handleClick} variant="contained">Add Label</Button>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>

                            <List component="nav" aria-label="main mailbox folders"
                      
                            >

                                {rects.map(r => (

                                    <ListItem
                                        selected={r.selected === true}
                                        onClick={(event) => selectRect(event, r.id)}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete" onClick={() => removeLabel(r.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemText
                                            primary={r.label.name}
                                        />
                                    </ListItem>
                                        
                                    
                                ))}
                            </List>
                            
                            

                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>

                            <AddLabelComponent setLoading={setLoading} labelAdded={handleLabelAdd} brand_id={brand_id} />
 
                        </CustomTabPanel>
                        
                    </Box>

                    <Divider sx={{ marginBottom: '20px' }} />
                    <Box sx={{ marginBottom: '20px',display: 'flex', justifyContent: 'space-between' }} >
                     
                        <Button onClick={handleResetZoom} variant="outlined" color="inherit" >Reset Zoom</Button>

                    <Button autoFocus onClick={handleSave} variant="contained" sx={{ marginRight: '10px' }}>
                    Save
                </Button>
                    </Box>
                    
                </Grid>
            </Grid>
        </div>
    );
};

export default ImagePolygonDrawer;