import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from "@mui/material/Link";
import { useState } from "react";
import VideoDetailsCard from "./videoDetails";

export default function VideoSummaryCard({video, channel, parentCallback}) {

    
    const openVideoDetails = () => {
        //debugger;
        console.log(video);
        parentCallback(video);
        //updateDetails(e, video.id)
    };
    
    const openUrl = () =>{
          let url = "";
          if(channel.channel_type == "YouTube"){
              return 'https://youtube.com/watch?v=' + video.video_id;
          }else if(channel.channel_type =="Twitch"){
              return 'https://www.twitch.tv/videos/' + video.video_id;
          }
          
          return null;
    };
    return (
        <div>

        <Card>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {video.video_title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {video.video_description.substring(0, 150)}{video.video_description.length >= 150 && '...'}
                </Typography>
            </CardContent>
            <CardActions>
                {channel && ( <Button component="a" href={'https://outboundl.ink/?' + openUrl()} size="small" target="_blank"><OpenInNewIcon /> View </Button>)}
                {channel && (<Link onClick={(e) => openVideoDetails()}>Details</Link>)}
      
            </CardActions>
        </Card>
        </div>
    );
}