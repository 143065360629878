import React, {useEffect} from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircleIcon from '@mui/icons-material/Circle';

const theme = {
    semiCircleWidth: '28rem',
    semiCircleHeight: '14rem',
    innerWidth: 'calc(28rem * 0.8)',
    innerHeight: 'calc(14rem * 0.8)',
    needleWidth: 'calc(28rem * 0.35)',
    needleHeight: 'calc(var(--semi-circle-height) * 0.2)',
    coverSize: 'calc(14rem * 0.25)'
};

const GaugeContainer = styled.div`
  width: var(--semi-circle-width);
`;

const Stats = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

const SemiCircle = styled.div`
  position: relative;
  width: var(--semi-circle-width);
  height: var(--semi-circle-height);
  border-radius: calc(var(--semi-circle-width) / 2) calc(var(--semi-circle-width) / 2) 0 0;
  background: linear-gradient(90deg, rgba(33,121,0,1) 0%, rgba(90,149,68,1) 25%, rgba(255,209,0,1) 25%, rgba(255,209,0,1) 75%, rgba(255,72,0,1) 75%, rgba(255,72,0,1) 100%);
  border-bottom: 10px solid #333;
`;

const Inner = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: var(--inner-width);
  height: var(--inner-height);
  border-radius: calc(var(--inner-width) / 2) calc(var(--inner-width) / 2) 0 0;
  background: #efefef;
  z-index: 100;
`;

const Needle = styled.div`
  position: absolute;
  width: var(--needle-width);
  height: var(--needle-height);
  right: 50%;
  transform: rotate(${props => props.rotation}deg);
  bottom: calc(var(--semi-circle-height) * -0.1);
  transform-origin: 100% 50%;
  z-index: 200;
`;

const Rectangle = styled.div`
    position: absolute;
    right: 0;
    height: var(--needle-height);
    width: 100%;
    background-color: #333;
    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0 50%);
`;

const Cover = styled.div`
  position: absolute;
  bottom: calc(var(--cover-size) * -0.5);
  left: 50%;
  transform: translateX(-50%);
  width: var(--cover-size);
  height: var(--cover-size);
  border-radius: 50%;
  background: #333;
  z-index: 205;
`;

const ChangeIndicator = ({ value }) => {
    let Icon, color;

    if (value > 0) {
        Icon = ArrowUpwardIcon;
        color = 'red';
    } else if (value < 0) {
        Icon = ArrowDownwardIcon;
        color = 'green';
    } else {
        Icon = <CircleIcon />
        color = 'orange';
    }

    return (
            <Icon style={{color: color}}/>
    );
};


const Gauge = ({ needleRotation, percentChanged, daysDuration }) => {

    const round = (value, exp) => {
        if (typeof exp === 'undefined' || +exp === 0)
            return Math.round(value);

        value = +value;
        exp  = +exp;

        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
            return NaN;

        // Shift
        value = value.toString().split('e');
        value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
    }
    
    return (
        <ThemeProvider theme={theme}>
        <GaugeContainer style={{
            '--semi-circle-width': theme.semiCircleWidth,
            '--semi-circle-height': theme.semiCircleHeight,
            '--inner-width': theme.innerWidth,
            '--inner-height': theme.innerHeight,
            '--needle-width': theme.needleWidth,
            '--needle-height': theme.needleHeight,
            '--cover-size': theme.coverSize,
        }}>
            <SemiCircle>
                <Inner />
                <Needle rotation={needleRotation}>
                    <Rectangle />
                </Needle>
                <Cover />
            </SemiCircle>
            <Stats>
                <ChangeIndicator value={percentChanged} />
                {(percentChanged < 0) && <span>Down</span>}
                {(percentChanged == 0) && <span>Unchanged</span>}
                {(percentChanged > 0) && <span>Up</span>}

                {(percentChanged != 0 && (<span>{round(percentChanged < 0 ? (percentChanged*-1) : percentChanged, 0)}%</span>))} in {daysDuration} days
            </Stats>
        </GaugeContainer>
        </ThemeProvider>
    );
};

export default Gauge;
