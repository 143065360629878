import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import * as React from "react";
import Title from "./Title";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";


export class HelpText extends React.Component { 
    render() {
       return ( <Grid container spacing={2} mt={2}>
            <Grid item>
                <HelpOutlineIcon sx={{ fontSize: 40 }} />
            </Grid>
            <Grid item xs={11}>
                <Typography mt={1}>
                    {this.props.children}
                </Typography>
            </Grid>
        </Grid>);
    }
}
