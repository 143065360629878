import {useEffect} from "react";
import * as React from "react";

export default function IssueStatusDisplay({status}) {

    const issueStatus = [
        {"key": 1, "text": "Open"},
        {"key": 2, "text": "Hold - Pending Review"},
        {"key": 3, "text": "Open - Awaiting response"},
        {"key": 4, "text": "General hold"},
        {"key": 100, "text": "Closed"},
        {"key": 101, "text": "Closed - no action required"},
        {"key": 102, "text": "Closed - resolved"},
        {"key": 103, "text": "Closed - unresolved"},
        {"key": 199, "text": "Closed - no issue found"}
    ];
    function statusText() {
        return getTextForStatus(status);
    }

    function getTextForStatus(status) {
        /*switch(issue.status){
            case 1:
                return "Open";
            case 2:
                return "On hold - review"
            case 3:
                return "Open - Waiting response";
            case 4:
                return "Open - on hold"
            case 100:
                return "Closed"
            case 101:
                return "Closed - no action required"
            case 102:
                return "Closed - resolved"
            case 103:
                return "Closed - unresolved"
        }*/
        var s = issueStatus.filter(f => f.key === status);
        if (s.length > 0) {
            return s[0].text;
        }

        return "Unknown status (" + status + ")";

    }
    return (
        <span>{getTextForStatus(status)}</span>
    );
}