import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from "@mui/material/Grid";
import * as PropTypes from "prop-types";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MDEditor from '@uiw/react-md-editor';
import authService from '../api-authorization/AuthorizeService'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
PersonAddIcon.propTypes = {fontSize: PropTypes.string};
export default function IssueEvent({issueEvent, issue, cb, showDate = true, hideControls = false}) {


    async function deleteComment(){
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/events/'+issue.id+'/' + issueEvent.id, {
            method: "DELETE",
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`}, 'Content-Type': 'application/json' },
        });
        authService.followIfLoginRedirect(response);
        console.log(response);
        cb()
    }
    const issueStatus = [
        { "key":1, "text": "Open"} ,
        { "key":2, "text": "Hold - Pending Review"},
        { "key":3, "text": "Open - Awaiting response"},
        { "key":4, "text": "General hold"},
        { "key":100, "text": "Closed"},
        { "key":101, "text": "Closed - no action required"},
        { "key":102, "text": "Closed - resolved"},
        { "key":103, "text": "Closed - unresolved"},
        {"key": 199, "text": "Closed - no issue found"}
    ];


    function getTextForStatus(status){
        console.log(status);
        var s = issueStatus.filter(f=> f.key === status);
        if(s.length > 0){
            return s[0].text;
        }

        return "Unknown status (" + status + ")";

    }
    
    
    function comment(){
        return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Grid container spacing={0} >
                    <Grid item xs={6}>
                        <strong>{issueEvent.action_user && !issueEvent.is_system_event ? issueEvent.action_user.display_name : "System"}
                        </strong> posted a comment.
                    </Grid>
                    <Grid item container xs={6} justifyContent="flex-end">
                        {moment(issueEvent.date).format('MMMM Do YYYY, h:mm:ss a')}
                    </Grid>
                </Grid>
                <Typography variant="body">
                    <MDEditor.Markdown source={issueEvent.comment} />
                </Typography>
                
            </CardContent>
            {!hideControls && !issueEvent.is_system_event && (
                <CardActions>
                    <Button size="small" onClick={deleteComment}><DeleteIcon fontSize='small' />Delete</Button>
                </CardActions>
            )}
        </Card>
        )
    }
    
    function statusChange(){
        console.log(issueEvent);
        console.log('status');
        return (
            <div>
                Status changed from '{getTextForStatus(issueEvent.old_status)}' to '{getTextForStatus(issueEvent.new_status)}' by {issueEvent.action_user != null ? issueEvent.action_user.display_name : "Digitals"}&nbsp;
            </div>
        )
    }
    
    function entityAttached(){
        
        return (
            <div><strong>{issueEvent.action_user.display_name}</strong> attached <strong>{issueEvent.entity.video.video_title}</strong></div>
        )
    }
    
    function issueCreated(){
        return (
            <div>
                {issueEvent.action_user && !issueEvent.is_system_event ? issueEvent.action_user.display_name : "System"} created this issue&nbsp;
                <span>{moment(issueEvent.date).format('MMMM Do YYYY, h:mm:ss a')}</span>
            </div>
        )
    }
    
    function issueClosed(){
        return (
            <h4>Issue closed</h4>
        )
    }
    function assignment(){
        return (
           <div>
                Issue assigned to <strong>{issueEvent.action_user.display_name}</strong>
           </div>
        )
    }
    function issueReviewHold(){
        return (
            <div>
                Item review date set: {moment(issueEvent.review_date).format('MMMM Do YYYY, h:mm:ss a')} by <strong>{issueEvent.action_user.display_name}</strong>
            </div>
        )
    }
    function issueReviewCleared(){
        return (
            <div>
                Review date reset by <strong>{issueEvent.action_user.display_name}</strong>
            </div>
        )
    }
    function issueReviewDateReached(){
        return (
            <div>
                {issueEvent.comment}
            </div>
        )
    }

    function entityRemoved(){

        return (
            <div><strong>{issueEvent.action_user.display_name}</strong> removed <strong>{issueEvent.entity.video.video_title}</strong></div>
        )
    }
    
    let event;
    let icon;
    let isDate = true;
    switch(issueEvent.event_type){
        case 0: //Comment
            event = comment();
            isDate = false;
            break;
        case 1://assignment
            event = assignment();
            icon = <PersonAddIcon fontSize='medium' />;
            break;
        case 2: //status change
            event = statusChange();
            break;
        case 3:
            event = entityAttached();
            icon = <AttachFileIcon fontSize='medium' />;
            break;
        case 4:
            icon = <AddCircleIcon />
            event = issueCreated();
            break;
        case 5:
            event = issueClosed();
            break;
        case 6:
            icon = <CalendarMonthIcon />
            event = issueReviewHold();
            break;
        case 7:
            event = issueReviewCleared();
            break;
        case 8:
            icon = <CalendarMonthIcon />
            event = issueReviewDateReached();
            break;
        case 9:
            icon = <DeleteForeverIcon />
            event = entityRemoved();
            break;
    }
    
    function layoutWithIcon(){
        return (
            <Grid  container direction="row" alignItems="center">
                <Grid item xs={0.5}  alignItems="center">
                    {icon}
                </Grid>
                <Grid item xs={11}>
                    {event}
                </Grid>
            </Grid>
        )
    }
    
    function layoutWithoutIcon(){
        return (
            <Grid container>
                <Grid item xs={12}>
                    {event}
                </Grid>
            </Grid>
        )
    }
    let layout
    if(icon){
        layout = layoutWithIcon()
    }else{
        layout = layoutWithoutIcon()
    }

    let date = moment(issueEvent.date);
    let dateDisplay = (
        <Typography sx={{ fontSize: 12, fontStyle: 'italic' }} gutterBottom>
            {date.format('MMMM Do YYYY, h:mm:ss a')}
        </Typography>
    );
    
    if(date.isAfter(moment().subtract(1, 'hours'))) {
        dateDisplay = (
            <Typography sx={{ fontSize: 12, fontStyle: 'italic' }} gutterBottom>
                {date.format('MMMM Do YYYY, h:mm:ss a')}
            </Typography>
        );
    }
    
    if(date.isSame(new Date(), "day")){
        dateDisplay = (
            <Typography sx={{ fontSize: 12, fontStyle: 'italic' }} gutterBottom>
                {date.format('h:mm:ss a')}
            </Typography>
        );
    }
    
    
    return (
        <Grid item sx={{ mt: 3,mb: 2}}>
            {showDate && isDate && (dateDisplay)}
            {layout}
        </Grid>
    );
}