import React from "react";
import Grid from "@mui/material/Grid";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Typography from "@mui/material/Typography";
import {createTheme, responsiveFontSizes, ThemeProvider} from '@mui/material/styles';
import {Link} from "react-router-dom";
export default function RandomQuote() {
    const quotes = [
        {
            quote: "In it for the art, no celebration",
            artist: "Sense MC",
            song: "No Levitation",
            link: "https://music.apple.com/gb/album/no-levitation/1651682143?i=1651682626"
        },
        {
            quote: "20 year career, and not a penalty missed\n" +
                "Mm, if it wasn't for me, some of your favourites would never exist",
            artist: "Ghetts",
            song: "Daily Duppy",
            link: "https://music.apple.com/gb/album/daily-duppy-feat-grm-daily/1614528018?i=1614528030"
        }
    ]


    let index = Math.floor(Math.random() * (quotes.length ));
    let quote = quotes[index];

    
    
    /*return (

                    <Typography variant="subtitle12" align="center"> &ldquo;{quote.quote}&rdquo;</Typography>
                    <div>
                        <Typography variant="song" align="center">{quote.artist} - {quote.song}</Typography>
               
                        <Typography variant="link" align="center">
                            &nbsp; - <Link to={ quote.link } target="_blank">Apple Music (new tab)</Link>
                        </Typography>
                    </div>

    )*/
    return (<div></div>)

}