import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import authService from '../api-authorization/AuthorizeService'
import moment from 'moment';
import * as PropTypes from "prop-types";
import {HelpText} from "../HelpText";
import SimpleBackdrop from "../backdrop";
import Box from "@mui/material/Box";
import RandomQuote from "../randomQuote";
import IssueStatusDisplay from "./issueStatusDisplay";
import {DataGrid} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";

function Item(props) {
  return null;
}

Item.propTypes = {children: PropTypes.node};
export default function Issues({updateTitle}) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const refresh =(updatedItems) => {

    console.log('refresh');
    console.log(updatedItems);
  };
  const columnsA = [
    { id: 'id', label: 'Issue Id', minWidth: 60, dtype: '' },
    {id: 'title', label: 'Title', dtype:''},
    {id: 'Status', label: 'Status', dtype:''},
    { id: 'date_created', label: 'Date Created', dtype: 'date' },
    { id: 'last_event', label: 'Last Updated', dtype: 'date'},
    {id: 'entities', label: 'Entites'},
    {id: 'assigned', 'label': 'Assigned to'}
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    // declare the async data fetching function
    updateTitle('Issues');
    const fetchData = async () => {
      setLoading(true);
      //const token = await authService.getAccessToken();
      const response = await fetch('/api/issues', {
        //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
      });
      authService.followIfLoginRedirect(response);
      const data = await response.json();
      setItems(data);
      setLoading(false);
    }

    // call the function
    fetchData()
        // make sure to catch any error
        .catch(console.error);;
  }, [])
  
  const noEntries = () => {
    return (
        <Box sx={{my:5}}>
          <Typography variant={"h5"} align={"center"}>You have no open issues!</Typography>
          <Typography align={"center"} sx={{mx:10, mt:5}}>There are no issues to manage. You can create issues from the videos that you review in each project.</Typography>
        </Box>
    )
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 70,
      renderCell: (params) => (
          <Link to={`/issues/${params.value}`}>#{params.value}</Link>
      )},
    { field: 'issue_title', headerName: 'Title' , width: 400},
    { field: 'last_event', headerName: 'Date Created', width: 130,
      valueGetter: (params) =>
          `${moment(params.row.date_created).format('DD/MM/YYYY')}` },
    { field: 'last_event', headerName: 'Last Event', width: 130,
      valueGetter: (params) =>
          `${moment(params.row.last_event).fromNow()}` },
    { field: 'entities', headerName: 'Entities', width: 80,
      valueGetter: (params) =>
          `${params.row.entities.length}`},
    { field: 'status', headerName: 'Status', width: 160,
      renderCell: (params) => (
          <IssueStatusDisplay status={params.value} />
      )}
  ]; 

    return (
        <div>

          

          <Paper sx={{ width: '100%', overflow: 'hidden' }}>

            {items.length > 0 && (<DataGrid
                rows={items}
                columns={columns}
                initialState={{
                  ...items.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
            />)}
            {items.length == 0 && (noEntries())}
          </Paper>
          <HelpText>
            You can create an issue from the details of a video
          </HelpText>
          <SimpleBackdrop  open={loading}/>
          
        </div>
    );
  
}
