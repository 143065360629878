import React, { Component, useCallback, useState, useEffect } from 'react';

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
export default function Billing() {


    return (
        <div>
            <Paper sx={{ width: '100%', overflow: 'hidden', mt:2 }}>
                <Box>
                    Billing to come
                </Box>
            </Paper>
        </div>
    );

}
