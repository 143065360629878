import * as React from 'react';

import {useEffect, useState} from "react";

/* 
    TODO: Enable call to: details/{projectId}/report/viewed/{reportEntryId}
 */
export default function IssueEntry({issue}) {


    console.log(issue);

    useEffect(() => {
        
    }, [issue])

    return (
        <div>

            ISSUE DETAILS {issue.id}
            
            <IssueEntry did={issue.id} />
        </div>
    );
}