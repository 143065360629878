import * as React from 'react';
import { useParams } from 'react-router-dom';
import {useEffect, useState} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import VideoSummaryCard from "../videoSummary";
import Grid from "@mui/material/Grid";
import VideoDetailsCard from "../videoDetails";
import Drawer from '@mui/material/Drawer';

import Button from "@mui/material/Button";
import authService from '../api-authorization/AuthorizeService'
import {Update} from "@mui/icons-material";
import SimpleBackdrop from "../backdrop";
import Box from "@mui/material/Box";
import Title from "../Title";
import IssueList from "../issues/issuelist";
import MDEditor, {commands} from "@uiw/react-md-editor";
import {useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Snackbar} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
//https://codesandbox.io/s/jzbml?file=/src/MyDialog.js:3636-3640
export default function ChannelDetails({updateTitle}) {

    const [ channel, setChannel] = React.useState({videos:[]});
    const { id } = useParams();
    const [loading, setLoading] = React.useState(true);
    const [active, setActive] = React.useState(false);
    const [ video, setVideo] = React.useState({});

    const fetchData = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/channels/details/' + id, {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setChannel(data);
        if(data.notes){
            setValue(data.notes.note_text);
        }
        updateTitle("Details: " + data.handle);
        setLoading(false);
    }
    useEffect(() => {
        // declare the async data fetching function
        updateTitle("Details")
        console.log('here2');
        console.log(channel);
        

        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);;
    }, [id])

    const [openDialog, setOpenDialog] = useState(false);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const updateDetails = (v) => {
        //debugger;
        setOpenDialog(true);
        console.log(v);
        setVideo(v);
    };

    const editor = useEditor({
        extensions: [StarterKit],
        content: "",
    });
    const [value, setValue] = React.useState("");

    const [noteLoading, setnoteLoading] = React.useState(false);
    const saveNotes = async () => {

        setnoteLoading(true);
        var req = {
            notes: value
        }
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/channels/details/'+channel.id+'/notes', {
            method: "PUT",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        console.log(response);
        setSnackOpen(true);
        setnoteLoading(false);
    }
    const [snackOpen, setSnackOpen] = React.useState(false);

    const handleClick = () => {
        setSnackOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    
    return (
        <div>
            <h1>{channel.channel_type}/{channel.handle}</h1>
            <VideoDetailsCard
                open={openDialog}
                handleOpenDialog={handleOpenDialog}
                handleCloseDialog={handleCloseDialog}
                row={video}
                active={active}
                parentCallback={updateDetails}
                channelId={id}
            />

            <Box component="div" sx={{mt: 3, mb: 2}}>
                <Title>Overview</Title>
                <strong>Average video discovery time:</strong> {channel.average_days_between_video} <br />
                <strong>Total Discovered Videos</strong> {channel.total_discovered_videos} <br />
            </Box>

            <Box component="div" sx={{mt: 3, mb: 2}}>
                <Title>Notes</Title>
                <p>Leave a note, something important. Anything. Everybody in your team can see this, not just you.</p>

                <MDEditor
                    value={value}
                    onChange={setValue}
                    preview="edit"
                    commands={[
                        commands.codeEdit, commands.codePreview,
                        commands.bold, commands.code, commands.quote,
                        commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6
                    ]}

                />
                <Box component="div" sx={{mt: 2}}>
                <LoadingButton loading={noteLoading} onClick={() => {saveNotes();console.log(value)}} variant="contained" sx={{mr: 2}}>
                   Save
                </LoadingButton>

                    {channel.notes &&
                        <small>Notes last updated by {channel.notes.user.display_name} on {moment(channel.notes.notes_last_updated).format('MMMM Do YYYY, h:mm:ssa')}</small>
                    }
                </Box>
            </Box>
            

            {channel.issues && channel.issues.length > 0 && (
                <Box component="div" sx={{mt: 3, mb: 2}}>
                    <Title>Issues related to this channel</Title>
                    <IssueList issues={channel.issues .reverse()} showadvanced={true} />
                </Box>
            )}
            

                <Box component="div" sx={{mt: 3, mb: 2}}>
                    <Title>Videos in this channel</Title>
                    {channel.videos.map((row) => {
                            return (
                                <VideoSummaryCard channel={channel} parentCallback={updateDetails} key={row.id} video={row} channelId={channel.id}  />
                            );
                        })}
                </Box>

            <Snackbar
                open={snackOpen}
                autoHideDuration={10000}
                onClose={handleClose}
                message="Note has been saved"
                action={action}
            />

            <SimpleBackdrop  open={loading}/>
        </div>
    );
}