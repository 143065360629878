import * as React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Fragment, useEffect} from "react";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import AppBar from '@mui/material/AppBar';
import CssBaseline from "@mui/material/CssBaseline";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import { styled, useTheme } from '@mui/material/styles';
import {Chip} from "@mui/material";
import Box from "@mui/material/Box";
import IssueEntry from "./IssueEntry";
import authService from '../api-authorization/AuthorizeService'
import moment from 'moment';
import IssueStatusDisplay from "./issueStatusDisplay";
import TablePagination from "@mui/material/TablePagination";
import RandomQuote from "../randomQuote";
export default function IssueList({issues, showadvanced, pageSize}) {

    const [ project, setProject] = React.useState({keywords:[]});
    const [ reports, setReports] = React.useState([]);
    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [active, setActive] = React.useState(false);
    const [drawOpen, setdrawOpen] = React.useState(false);
    const [issueDetails, setIssueDetails] = React.useState({});
    const navigate = useNavigate();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const fetchData = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/details/' + id + '/report', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setReports(data.reverse());
        setLoading(false);
    }

    const fetchProjectData = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/details/' + id , {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setProject(data);
    }

    useEffect(() => {
        
        if(pageSize){
            setRowsPerPage(pageSize);
        }
        console.log('use effect');
        fetchData()
            // make sure to catch any error
            .catch(console.error);
        fetchProjectData()
            // make sure to catch any error
            .catch(console.error);
    }, [id])



    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setdrawOpen(open);
    };
    const updateDetails = (v) => {
        console.log(v);
        //debugger;
        toggleDrawer(true);

        setIssueDetails(v);
        setdrawOpen(true);
    };

    const styles = {
        navBar: {'top': AppBar.height}
    }
    const drawerWidth = "30em";

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    }));
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const noEntries = () => {
        return (
            <TableRow>
                <TableCell colSpan={10}>
                    There are no open issues to deal with. 
                    <Box mt={5}>
                        <RandomQuote />
                    </Box>
                </TableCell>
            </TableRow>
        )
    }
    return (
        <div>
            {false && <Box component="div" sx={{ paddingBottom: 2 }}>
                {project.keywords.map((row) => (
                    <Chip label={row} key={row} />
                ))}
            </Box>} 

            <Grid
                container
                direction="row"
            >
                <Paper sx={{ width: '100%', overflow: 'hidden', mt:2 }}>
                    <TableContainer sx={{ maxHeight: 700 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Issue</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Last Activity</TableCell>
                                <TableCell>Opened</TableCell>
                                {showadvanced && <TableCell>Status</TableCell>}
                            </TableRow>
                        </TableHead> 
                        <TableBody>
                            {issues
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
 
                                <TableRow
                                    hover
                                    key={row.id}
                                    onClick={(event) =>navigate('/issues/' + row.id)}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                                >
                                    <TableCell scope="row"
                                               style={{fontWeight: (!row.viewed || row.viewed === false) ? "bold" : "normal"}}
                                    >
                                        {row.id}
                                    </TableCell>
                                    <TableCell scope="row">
                                        {row.issue_title}
                                    </TableCell>
                                    <TableCell>
                                        <IssueStatusDisplay status={row.status} />
                                    </TableCell>
                                    <TableCell>
                                        {moment(row.last_event).format("MMMM Do YYYY, h:mm:ss ")}
                                    </TableCell>
                                    <TableCell>
                                        {moment(row.date_created).format("MMMM Do YYYY, h:mm:ss")}
                                    </TableCell>
                                    {showadvanced && <TableCell><IssueStatusDisplay status={row.status} /></TableCell>}
                                </TableRow>
                            ))}
                            {(!issues|| issues.length === 0) && noEntries()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={issues.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            </Grid>

            <Fragment>
                <CssBaseline />
                <Drawer

                    sx={{
                        zIndex: 1000,
                        width: "40%",
                        [`& .MuiDrawer-paper`]: {
                            pt: 9,
                            width: "40%",
                        },
                    }}
                    anchor='right'
                    open={drawOpen} containerStyle={styles.navBar}
                    onClose={toggleDrawer(false)}
                >
                    <DrawerHeader>
                        <IconButton onClick={toggleDrawer(false)}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <strong>Video Review:&nbsp;</strong>
                        
                    </DrawerHeader>
                    <IssueEntry issue={issueDetails} project={project} />
                </Drawer>
            </Fragment>
        </div>
    );
}