import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';

const ExpandableText = ({ text, maxLines = 10 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const getLines = (text) => {
        return text.split('\n');
    };

    const lines = getLines(text);

    const displayedText = isExpanded ? text : lines.slice(0, maxLines).join('\n');

    return (
        <div>
            <Typography style={{ whiteSpace: 'pre-line', fontSize:'12px' }}>
                {displayedText}
                {lines.length > maxLines && !isExpanded && '...'}
            </Typography>
            {lines.length > maxLines && (
                <Button variant="text" color="primary" onClick={handleToggle}>
                    {isExpanded ? 'Show less' : 'Read more'}
                </Button>
            )}
        </div>
    );
};


export default ExpandableText;
