import React, { useEffect } from 'react';
import FormDialog from './project/formdialog'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import authService from './api-authorization/AuthorizeService'
import {HelpText} from "./HelpText";
import SimpleBackdrop from "./backdrop";
import Box from "@mui/material/Box";
import RandomQuote from "./randomQuote";
export default function Projects({updateTitle}) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  
  const refresh =(updatedItems) => {
    console.log('refresh');
    console.log(updatedItems);
    
    fetchData()
        // make sure to catch any error
        .catch(console.error);;
        
  };
  const columns = [
    { id: 'name', label: 'Name', minWidth: 170 }
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const fetchData = async () => {
    setLoading(true);
    //const token = await authService.getAccessToken();
    const response = await fetch('/api/projects', {
      //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
    });
    authService.followIfLoginRedirect(response);
    const data = await response.json();
    setItems(data);
    setLoading(false);
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    console.log('projects');
    console.log(updateTitle);
    // declare the async data fetching function
    updateTitle('Projects');
    

    // call the function
    fetchData()
        // make sure to catch any error
        .catch(console.error);;
  }, [])

  const noEntries = () => {
    return (
        <TableRow>
          <TableCell colSpan={10}>
            You haven't yet created a project.
            <Box mt={5}>
              <RandomQuote />
            </Box>
          </TableCell>
        </TableRow>
    )
  }
    return (
        <div>
          {authService.isInRole(["ADMIN", "CREATEPROJECT"]) && (
              <FormDialog items={items} cb={refresh} />
          )}
          <Paper sx={{ width: '100%', overflow: 'hidden', mt:2 }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow key="r">
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                    ))}
                    <TableCell>Unviewed items</TableCell>
                    <TableCell>Total Entries</TableCell>
                    <TableCell>Open Issues</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                              <TableCell><Link to={`/projects/${row.id}`} >{row.name}</Link></TableCell>
                              <TableCell>{row.pending_items}</TableCell>
                              <TableCell>{row.total_video}</TableCell>
                              <TableCell>{row.open_issues.length}</TableCell>
                            </TableRow>
                        
                        );
                      })}
                  {(!items || items.length === 0) && noEntries()}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <HelpText>
            These are your projects. This allows you to have different projects to manage different tasks of data.

            You may want one project to look for mentions of a particular brand or another to look for mentions of a particular 
            type of product.

            
          </HelpText>
          
          <SimpleBackdrop  open={loading}/>
        </div>
    );
  
}
