import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MuiChipsInput } from 'mui-chips-input'
import authService from '../api-authorization/AuthorizeService'
import StyledActionButton from "../GreenButton";
import {useEffect} from "react";
export default function FormDialog({cb, brand, openEditMode, onClose}) {

    const [ items, setItems] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [projectName, setProjectName] = React.useState("");

    useEffect(() => {
        // declare the async data fetching function
        //updateTitle('Brands');


       if(brand) {
           setProjectName(brand.name);
           setChips(brand.domains.map(x=>x.domain));
       }
       
       if(openEditMode){
           setOpen(openEditMode);
       }
    }, [])
    const handleClickOpen = async () => {

        
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        onClose();
    };
    const [chips, setChips] = React.useState([])

    const handleChange = (newChips) => {
        setChips(newChips)
    }
    const handleNameChange = (e) => setProjectName(e.target.value);
    const create = async (event) => { 
        console.log(chips);
        console.log(projectName);
        //const newItems = items;
        //newItems.push({name: projectName})
        //setItems(newItems);


        var req = {
            name: projectName,
            domains: chips
        }
        if(openEditMode){
//const token = await authService.getAccessToken();
            const response = await fetch('/api/organisation/brands/' + brand.id, {
                method: "PUT",
                headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
                body: JSON.stringify(req)
            });
            authService.followIfLoginRedirect(response);
            const data = await response.json();


            onClose();
            setOpen(false);
        }else{
            //const token = await authService.getAccessToken();
            const response = await fetch('/api/organisation/brands', {
                method: "POST",
                headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
                body: JSON.stringify(req)
            });
            authService.followIfLoginRedirect(response);
            const data = await response.json();

            setOpen(false);
            setChips([]);
            setProjectName("");
            cb();
        }
        //this.setState({ items: data, loading: false });

    }
    
    return (
        <div>
            {!brand && (<StyledActionButton onClick={handleClickOpen}>
                Create
            </StyledActionButton>)}
            <Dialog open={(openEditMode||open)} onClose={handleClose}>
                <DialogTitle>Create Project</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To subscribe to this website, please enter your email address here. We
                        will send updates occasionally.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Brand Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={projectName}
                        onChange={handleNameChange}
                    />
                    
                    <DialogContentText className="mt-3">
                       Enter domains for this brand
                    </DialogContentText>
                    
                    <MuiChipsInput margin="dense" label="Domains" variant="standard" fullWidth={true} value={chips} onChange={handleChange}
                                   validate={(chipValue) => {
                                       return {
                                           isError: chipValue.length <= 3 || !(/^[.a-zA-Z0-9-]{1,61}(\.[a-zA-Z]{2,}){1,}$/.test(chipValue)),
                                           textError: 'the value must be at least 3 characters long'
                                       }
                                   }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={create}>{openEditMode?"Save Changes":"Create New"}</Button>
                </DialogActions>
            </Dialog>
            
        </div>
    );
}