import React, {Component, Fragment} from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link as RouterLink} from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
export class LoginMenu extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            isAuthenticated: false,
            userName: null,
            anchorEl:null,
            open: false
        };
    }

    handleClick(event) {
        console.log(event.currentTarget);
        this.setState({open:true, anchorEl: event.currentTarget});
    }
    handleClose () {
        this.setState({open:false, anchorEl:null});
    }
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name,
            displayName: user.display_name,
            firstName: user.first_name
        });
    }

    render() {
        const { isAuthenticated, userName ,displayName, firstName} = this.state;
        if (!isAuthenticated) {
            const loginPath = `/Identity/Account/Login?ReturnUrl=%2F`;
            return this.anonymousView(loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = { local: true };
            return this.authenticatedView(userName, profilePath, logoutPath, logoutState,displayName, firstName);
        }
    }

    logout(){
        window.location ='/Identity/Account/Logout?returnUrl=%2F';
    }
    manage(){
        window.location ='/Identity/Account/Manage';
    }
    authenticatedView(userName, profilePath, logoutPath, logoutState, displayName, firstName) {
        return (
            <React.Fragment>
                <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                    <Tooltip title="Account settings">
                        <IconButton
                            onClick={this.handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={this.state.open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={this.state.open ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 32, height: 32 }}>{firstName.substring(0, 1)}</Avatar>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    anchorEl={this.state.anchorEl}
                    id="account-menu"
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClick={this.handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={this.handleClose}>

                        <NavLink component={Link}
                                 href={"/Identity/Account/Manage"} className="text-dark">
                            
                            <Stack direction={"row"}>
                                <Avatar /> 
                                <Typography>{firstName}</Typography>
                            </Stack>
                        </NavLink>
                    </MenuItem>
                    <Divider />



                    <MenuItem    component={'a'}
                                 href={"/organisation"}>
                        <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                        Organisation
                    </MenuItem>

                    {authService.isInRole(["ADMIN"]) && (
                        <MenuItem    component={Link}
                                     href={"/Identity/Account/Manage"}>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                  
                        )}
                    {authService.isInRole(["ADMIN"]) && (

                        <MenuItem>
                            <RouterLink role="menuitem"  to={"/billing"}>
                                <ListItemIcon>
                                    <PersonIcon fontSize="small" />
                                </ListItemIcon>
                                Billing
                            </RouterLink>
                        </MenuItem>

                    )}


                    <MenuItem>
                        <NavLink className="text-dark" onClick={this.logout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </NavLink>
                    </MenuItem>
                </Menu>
            </React.Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}