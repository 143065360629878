import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Signup1 from "./signup1";
import Signup2 from "./signup2";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import SimpleBackdrop from "../backdrop";
const steps = ['About you', 'About your business'];


export default function SignupWrapper() {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [stepOneData, setStepOneData] = React.useState({});
    const [stepTwoData, setStepTwoData] = React.useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [isLoading, setisLoading] = React.useState(false);
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    useEffect(() => {

        const logStart = async () => {
            var req = {
                activation_code: searchParams.get("EmailKey")
            }
            console.log(req);

            //const token = await authService.getAccessToken();
            const response = await fetch('/api/signup/validate', {
                method: "POST",
                headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
                body: JSON.stringify(req)
            });
            console.log(response);
            if(response.status === 204) {

            }else{
                navigate('/');
            }
        };
        
        logStart();
    }, []);
    
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
     
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    
    const signupCallback = (p) =>{
        setStepOneData(p);
        console.log("step one done");
        handleNext();
    }

    const doRegister = async (p) => {
        var req = {
            activation_code: searchParams.get("EmailKey"),
            first_name: stepOneData.first_name,
            last_name: stepOneData.last_name,
            password: stepOneData.password,
            business_name: p.business_name,
            email: "ker"
        }
        console.log(req);


        //const token = await authService.getAccessToken();
        const response = await fetch('/api/signup/complete', {
            method: "POST",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        console.log(response);
        if(response.status === 204||response.status === 200) {
            window.location = "/";
        }else{
            window.location =('/Identity/Account/Login');
        }  
    };
    const signup2Callback = async (p) =>{
        setStepTwoData(p);
        console.log(stepTwoData);
        console.log("steptwoi done");
  
        setisLoading(true);
        await doRegister(p);
        //handleNext();
        
        //alert('submit data now');
    }
    
    const registerForm = () => {
        return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>

                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep == 0 && <Signup1 data={stepOneData} callback={signupCallback} />}
                        {activeStep == 1 && <Signup2 data={stepTwoData} callback={signup2Callback} />}

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />


                            <Button onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box> <SimpleBackdrop open={isLoading}/>
        </Container> );
    };
    function loadingView() {
        return (<div>Loading.. </div>)
    }
    return (registerForm());
}