import React from 'react';

const NumberCount = ({number, text}) => {
    return (
        <div style={styles.container}>
            <p style={styles.bigText}>{number}</p>
            {text && (<p style={styles.smallText}>{text && text != "" ? text : ""}</p>)}
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center'
    },
    bigText: {
        fontSize: '24px',
        fontWeight: 'bold',
        margin: '0'
    },
    smallText: {
        fontSize: '12px',
        margin: '0',
        color: '#9a9a9a'
    }
};

export default NumberCount;