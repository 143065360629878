import React, { Component,  useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import Dashboard from "./components/Dashboard";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import authService from './components/api-authorization/AuthorizeService'
import { redirect } from "react-router-dom";
import Cookies from 'js-cookie';
import Projects from "./components/Projects";
import ProjectDetails from "./components/project/details";
import Channels from "./components/channels/Channels";
import ChannelDetails from "./components/channels/details";
import Issues from "./components/issues/Issues";
import IssueDetails from "./components/issues/issueDetails";
import Organisation from "./components/organisation/organisation";
import Main from "./components/Main";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import { useTheme } from '@mui/material/styles';
import {
    Experimental_CssVarsProvider as CssVarsProvider,
    experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RandomQuote from "./components/randomQuote";
import Typography from "@mui/material/Typography";
import { useLocation } from 'react-router-dom'
import Signup from "./components/signup/signup";
import SignupWrapper from "./components/signup/signupwrapper";
import Brands from "./components/brands/brands";
import BrandDetails from "./components/brands/brandDetails";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from "@mui/material/CssBaseline";
import Billing from "./components/billing/billing";
import Reporting from "./components/reporting/reports";
export default function App () {
    
    const [loadingState, setLoadingState] = React.useState(true);
    
    const [title, setTitle] = React.useState("Digitals Title");
    const [loggedInNow, setLoggedInNow] = React.useState(false);
    const theme = createTheme({
        
        typography: {
            fontFamily: [
                '-apple-system',
                'system-ui',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            "fontSize": 14,
            "fontWeightLight": 300,
            "fontWeightRegular": 400,
            "fontWeightMedium": 500
        },
        palette: {
            /*primary: {
                light: '#6F6058',
                main: '#3B3435',
                dark: '#241F20',
                contrastText: '#fff',
            },
            secondary: {
                light: '#FF7E43',
                main: '#221e1f',
                dark: '#221e1f',
                contrastText: '#000',
            },
            action: {
                active: '#FF641D',
                hover: '#6F6058',
                focus: '#FF641D'
            }
            
             */
            primary: {
                main: '#FF641D',
            },
            secondary: {
                main: '#221E1F',
            },
            action: {
                active: '#FF641D',
                hover: '#fee0d2',
                focus: '#FF641D'
            }
        },
        overrides: {
            
        },
        icon: {
            color: 'red',
        },
        components: {
            MuiTableCell: {
                head: {
                    backgroundColor: "grey",
                }
            },
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: "rgb(59, 52, 53, 0)",
                        color: "#FFF"
                    }
                }
            }
        }
    });
    let isAuth = false;

    const location = useLocation();
    
    useEffect(() => {

        
        console.log(location.pathname);
        
        if(!isSignup()) {
            console.log('asdasd');
            authService.ensureUserManagerInitialized((login) => {
                setLoggedInNow(login);
                setLoadingState(false);
            });
        }
    
       
        
    }, []);

    const updateTitle2 = (title)=>{
        setTitle(title)
    }
    
    const appRoutes = [
        {
            path: '/',
            element: <Main updateTitle={updateTitle2}/>,
            requireAuth: true
        },
        {
            path: '/projects',
            requireAuth: true,
            element: <Projects updateTitle={updateTitle2}/>
        },{

            path: '/projects/:id',
            requireAuth: true,
            element: <ProjectDetails updateTitle={updateTitle2}/>
        },
        {
            index: true,
            path: '/channels',
            requireAuth: true,
            element: <Channels updateTitle={updateTitle2}/>
        },{
            index: true,
            path: '/channels/:id',
            requireAuth: true,
            element: <ChannelDetails updateTitle={updateTitle2}/>
        },{
            index: true,
            path: '/issues',
            requireAuth: true,
            element: <Issues updateTitle={updateTitle2} />
        },{
            path: '/issues/:id',
            requireAuth: true,
            element: <IssueDetails updateTitle={updateTitle2}/>
        },{
            path: '/organisation',
            requireAuth: true,
            element: <Organisation updateTitle={updateTitle2} />
        },{
            path: '/brands',
            requireAuth: true,
            element: <Brands updateTitle={updateTitle2} />
        },{
            path: '/brands/:id',
            requireAuth: true,
            element: <BrandDetails updateTitle={updateTitle2} />
        },{
            path: '/billing',
            requireAuth: true,
            element: <Billing updateTitle={updateTitle2} />
        },{
            path: '/reporting',
            requireAuth: true,
            element: <Reporting updateTitle={updateTitle2} />
        }
        ];

    const loggedIn = () => {
        return (
            <Dashboard title={title}>
                <Routes>
                    {appRoutes.map((route, index) => {
                        const { element, ...rest } = route;

                        return <Route key={index} {...rest} element={element}  />;
                    })}
                    <Route path='/u/logout' component={() => {
                        window.location.href = '/Identity/Account/Logout?returnUrl=%2F';
                        return null;
                    }}/>
                </Routes>
            </Dashboard>
        )
    }
    
    const isSignup = () =>{
        console.log(location);
        console.log('asd');
        console.log(location.pathname);
        console.log(location.pathname.toLowerCase().startsWith("/signup"));
        return location.pathname.toLowerCase().startsWith("/signup");
    }
    
    const loading = () => {
        return (

        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={3} alignItems="center"
                  justifyContent="center">
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={3} >
                        <img src="/digitalslogov2_notag.png" />
                    
                        <Typography variant="subtitle1" gutterBottom>
                            Loading environment...
                        </Typography>
                    </Grid>
                </Grid>
                    
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={3}>
                        <Box>
                            <RandomQuote />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {!loggedInNow && isSignup() && (
                <React.Fragment>
                    <SignupWrapper />
                </React.Fragment>
            )}
            {!loggedInNow && !isSignup() && loading()}
            {loggedInNow && !loadingState &&
                (
                    <React.Fragment>
                        {loggedIn()}
                    </React.Fragment>
                )
            }
        </ThemeProvider>
    );
  
}
