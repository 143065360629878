import * as React from 'react';
import { useParams } from 'react-router-dom';
import {useEffect, useState} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import VideoSummaryCard from "../videoSummary";
import Grid from "@mui/material/Grid";
import VideoDetailsCard from "../videoDetails";
import Drawer from '@mui/material/Drawer';

import Button from "@mui/material/Button";
import authService from '../api-authorization/AuthorizeService'
import {Update} from "@mui/icons-material";
import SimpleBackdrop from "../backdrop";
import Box from "@mui/material/Box";
import Title from "../Title";
import IssueList from "../issues/issuelist";
import MDEditor, {commands} from "@uiw/react-md-editor";
import {useEditor} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import moment from "moment/moment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Chip, Snackbar} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Gauge from "./gauge";
import {LineChart} from "@mui/x-charts/LineChart";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import MoneyIcon from "@mui/icons-material/Money";
import Alert from "@mui/material/Alert";
import DetailsIcon from "@mui/icons-material/Details";
//https://codesandbox.io/s/jzbml?file=/src/MyDialog.js:3636-3640
export default function ChannelDetails({channel}) {
    const [value, setValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [noteLoading, setnoteLoading] = React.useState(false);
    
    const updateDetails = (v) => {

    };

    useEffect(() => {
        if(channel.notes){

            setValue(channel.notes.note_text);
        }
        addChartData(channel);
    }, [channel])
    const saveNotes = async () => {

        setnoteLoading(true);
        var req = {
            notes: value
        }
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/channels/details/'+channel.id+'/notes', {
            method: "PUT",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        console.log(response);
        setSnackOpen(true);
        setnoteLoading(false);

    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    function getPercentageChange(numbers) {
        if (numbers.length < 2) {
            throw new Error('Array must contain at least two numbers to calculate percentage change.');
        }

        const newest = numbers[0];
        const oldest = numbers[numbers.length - 1];

        // Calculate the percentage change 
        let percentageChange = ((newest - oldest) / oldest) * 100;
        if (!isFinite(percentageChange)) {
            percentageChange = 1000;
        }
        return round(percentageChange,2);
    }

    const [chartData, setChartData] = useState([]);
    const [xLabels, setxLabels] = useState([]);

    const round = (value, exp) => {
        if (typeof exp === 'undefined' || +exp === 0)
            return Math.round(value);

        value = +value;
        exp  = +exp;

        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
            return NaN;

        // Shift
        value = value.toString().split('e');
        value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

        // Shift back
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
    }

    function getTotalDaysDifference(dates) {
        if (dates.length < 2) {
            throw new Error('Array must contain at least two dates to calculate the difference.');
        }

        // Helper function to convert a date string 'dd-mm-yyyy' to a Date object
        function parseDate(dateStr) {
            const [year, month, day] = dateStr.split('-').map(Number);
            return new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date
        }

        const newestDate = parseDate(dates[0]);
        const oldestDate = parseDate(dates[dates.length - 1]);

        // Calculate the difference in time
        const timeDifference = newestDate - oldestDate;

        // Convert time difference from milliseconds to days
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

        return Math.round(daysDifference); // Rounding to the nearest whole number
    }

    const addChartData = (data) => {
        

        const keysPressure = Object.keys(data.rankings);
        const valuesPressure = Object.values(data.rankings);

        const every10thKeysPressure = [];
        const every10thValuesPressure = [];

        for (let i = 0; i < keysPressure.length; i++) {
            every10thKeysPressure.push(keysPressure[i]);
            every10thValuesPressure.push(round(valuesPressure[i],2));
        }

        //video additions 
        setChartData([{
            data: every10thValuesPressure.reverse(),
            label: "Pressure"
        }]);

        setxLabels(every10thKeysPressure.reverse());
    }


    
    return (
        <React.Fragment>
            <Title>{channel.channel_name}</Title>
            <Box component="div" sx={{mt: 0, mb: 2}}>
                <Title>Overview</Title>
                <strong>Average video discovery time:</strong> {channel.average_days_between_video} <br/>
                <strong>Total Discovered Videos</strong> {channel.total_discovered_videos} <br/>
                <strong>Subscribers </strong> {channel.subscriber_count} <br/>
                <strong>Verified </strong> {channel.verified ? "yes":"no"} <br/>
            </Box>

            <Title>Channel Description</Title>
            <Box>

                {channel.channel_description}
            </Box>
            <Box component="div" sx={{mt: 3, mb: 2}}>
                <Title>Pressure</Title>
                <Grid container spacing={0}>
                    
                    <Grid item xs={6} direction="row"
                          justifyContent="flex-end"
                          alignItems="center">
                        <Stack direction="column"
                               justifyContent="flex-end"
                               alignItems="center">
                            <Typography sx={{fontWeight:"bold"}}>Current Pressure</Typography>
                            <Typography variant={"h3"}  sx={{mb:2, textAlign:"center"}}>{round(channel.overall_pressure_rating,2)}</Typography>
                            <Gauge needleRotation={(channel.overall_pressure_rating * 18)} percentChanged={getPercentageChange(Object.values(channel.rankings))} daysDuration={getTotalDaysDifference(Object.keys(channel.rankings))}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={6}  alignItems={"center"}
        
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }}
                        >
                            <LineChart
                                series={chartData}
                                height={200}
                                disableHighlight
                                disableLineItemHighlight
                                xAxis={[{ scaleType: 'point', data: xLabels }]}
                                leftAxis={null}
                                margin={{
                                    left: 30,
                                    right: 10,
                                    top: 30,
                                    bottom: 10,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            <Title>Urls</Title>
            <Typography>These are the urls found in the current users profile. </Typography>
            <Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Last seen</TableCell>
                                <TableCell>Published Url</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {channel.channel_urls.map((row) => (
                                <TableRow
                                    hover
                                    key={row.id}
                                >
                                    <TableCell>{moment(row.date_last_seen).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                                    <TableCell><a href={`https://outboundl.ink/?${row.url}`} target="_blank"
                                                  rel="noopener noreferrer">{row.url}</a>
                                    </TableCell>

                                    {/*<TableCell>*/}
                                    {/*    {row.request_result_avaialble && (*/}
                                    {/*        <Button key={row.id} disabled={downloadingScreenshot} onClick={()=>{openPreview(row.id)}}>*/}
                                    {/*            <DetailsIcon />*/}
                                    {/*        </Button>*/}
                                    {/*    )}*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            
            <Box component="div" sx={{mt: 3, mb: 2}}>
                <Title>Notes</Title>
                <p>Leave a note, something important. Anything. Everybody in your team can see this, not just you.</p>

                <MDEditor
                    value={value}
                    onChange={setValue}
                    preview="edit"
                    commands={[
                        commands.codeEdit, commands.codePreview,
                        commands.bold, commands.code, commands.quote,
                        commands.title1, commands.title2, commands.title3, commands.title4, commands.title5, commands.title6
                    ]}

                />
                <Box component="div" sx={{mt: 2}}>
                    <LoadingButton loading={noteLoading} onClick={() => {saveNotes();console.log(value)}} variant="contained" sx={{mr: 2}}>
                        Save
                    </LoadingButton>

                    {channel.notes &&
                        <small>Notes last updated by {channel.notes.user.display_name} on {moment(channel.notes.notes_last_updated).format('MMMM Do YYYY, h:mm:ssa')}</small>
                    }
                </Box>
            </Box>


            {channel.issues && channel.issues.length > 0 && (
                <Box component="div" sx={{mt: 3, mb: 2}}>
                    <Title>Issues related to this channel</Title>
                    <IssueList issues={channel.issues .reverse()} showadvanced={true} />
                </Box>
            )}


            <Box component="div" sx={{mt: 3, mb: 2}}>
                <Title>Videos in this channel</Title>
                {channel.videos.map((row) => {
                    return (
                        <VideoSummaryCard channel={channel} parentCallback={updateDetails} key={row.id} video={row} channelId={channel.id}  />
                    );
                })}
            </Box>

            <Snackbar
                open={snackOpen}
                autoHideDuration={10000}
                onClose={handleClose}
                message="Note has been saved"
                action={action}
            />

            <SimpleBackdrop  open={loading}/>
        </React.Fragment>
    )
    
}