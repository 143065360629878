import * as React from 'react';
import { useParams } from 'react-router-dom';
import {Fragment, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import VideoSummaryCard from "../videoSummary";
import {LinearProgress, ListItem, ListItemAvatar, Select} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import IssueEvent from "./issueEvent";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import Person2Icon from '@mui/icons-material/Person2';
import LockIcon from '@mui/icons-material/Lock';
import AddIssueUpdate from "./addIssueUpdate";
import {DatePicker} from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import authService from '../api-authorization/AuthorizeService'
import SimpleBackdrop from "../backdrop";
import IssueStatusDisplay from "./issueStatusDisplay";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import ReportEntry from "../reportEntry";
import Drawer from "@mui/material/Drawer";
import {styled} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Title from "../Title";
import ChannelDetails from "../reporting/channelDetails";
import CssBaseline from "@mui/material/CssBaseline";
export default function IssueDetails() {

    const [project, setProject] = React.useState({keywords: []});
    const [issue, setIssue] = React.useState({entities: [], events: []});
    const {id} = useParams();
    
    const [loading, setLoading] = React.useState(true);
    const [multipleEntities, setMultipleEntities] = React.useState(false);
    const [teamMembersInvolved, setTeamMembersInvolved] = React.useState([]);
    const [teamMembers, setTeamMembers] = React.useState([]);
    const [holdValue, setHoldValue] = React.useState(null);
    const [newIssueStatus, setNewIssueStatus] = React.useState(1);
    const [attachedEntities, setAttachedEntities] = React.useState([]);
    const [reportDetails, setReportDetails] = React.useState({});
    const [drawOpen, setdrawOpen] = React.useState(false);

    const handleNewIssueStatusChange = async (event) => {
        setNewIssueStatus(event.target.value);

        var req = {
            new_status: event.target.value
        }
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/status/' + issue.id, {
            method: "PUT",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json'},
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        fetchData();
    };
    const fetchData = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/' + id, {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setIssue(data);

        if (data.entities && data.entities.length > 1) {
            setMultipleEntities(true);
        } else {
            setMultipleEntities(false);
        }
        data.events.reverse();
        if (data.review_date) {
            setHoldValue(moment(data.review_date));
        }
        setAttachedEntities(data.entities.filter((e)=> e.deleted === false));
        setNewIssueStatus(data.status);
        setLoading(false);
    }

    const fetchLoadPre = async () => {
        toggleDrawer(true);
        setdrawOpen(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/details/15/pre/1870', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        const data = await response.json();
        setReportDetails(data);
        
        
    }
    

    useEffect(() => {
        fetchData()
            // make sure to catch any error
            .catch(console.error);

    }, [id])
    


    

    const createReviewDate = async (event) => {


        var req = {
            review_date: holdValue.toISOString()
        }
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/review_date/' + issue.id, {
            method: "PUT",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json'},
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        console.log(response);
        fetchData();
    }

    function handleSaveReviewDate() {
        createReviewDate()
    }

    const resetReviewDate = async (event) => {


        var req = {}
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/review_date/' + issue.id + '/reset', {
            method: "PUT",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json'},
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        console.log(response);
        fetchData();
    }

    function callback() {
        fetchData();
    }

    function handleResetReviewDate() {
        resetReviewDate()
    }


    function loadingView() {
        return (<div>Loading.. <SimpleBackdrop open={loading}/></div>)
    }
    const issueStatus = [
        {"key": 1, "text": "Open"},
        {"key": 2, "text": "Hold - Pending Review"},
        {"key": 3, "text": "Open - Awaiting response"},
        {"key": 4, "text": "General hold"},
        {"key": 100, "text": "Closed"},
        {"key": 101, "text": "Closed - no action required"},
        {"key": 102, "text": "Closed - resolved"},
        {"key": 103, "text": "Closed - unresolved"}
    ];

    const [removeEntity, setRemoveEntity] = React.useState(null);
    const [confirmRemove, setConfirmRemove] = React.useState(false);

    async function startRemoveEntity(entity) {
        console.log(entity);
        setRemoveEntity(entity);
        setConfirmRemove(true);
    }




    async function doRemoveEntity(){

        setConfirmRemove(false);
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/issues/' + issue.id + '/entity/' + removeEntity.id, {
            method: "DELETE",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json'},
        });
        authService.followIfLoginRedirect(response);
        if(response.status > 199 && response.status < 299){
            await fetchData();
            
        }else{
            console.log(response);
            setLoading(false);
        }
    };

    function closeDiagloeRemove() {
        setConfirmRemove(false);
    }
    const styles = {
        navBar: {'top': AppBar.height}
    }
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    }));
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if(!open){
            setReportDetails(null);
        }
        setdrawOpen(open);
    };
    
    const channelView = () =>{
        let channels = attachedEntities.filter(e=>e.channel);
        if(channels.length == 0){
            return;
        }
        
        return (
            <React.Fragment>
                <Title>Channels Related To Issue</Title>
                <Box>
                    {channels.map((row) => (
                        <Box  key={row.id}>
                            {row.channel.handle}
                            {<Button onClick={() => loadData(1, row.channel.id)}>Details</Button>}
                        </Box>
                    ))}
                </Box>
            </React.Fragment>
        )
    }
    
    
    const videoView = () =>{
        let channels = attachedEntities.filter(e=>e.video);
        if(channels.length == 0){
            return;
        }

        return (
            <Box>
                <Title>Videos Related To Issue</Title>
                {channels.map((row) => (
                    <Box key={row.id}>
                        <VideoSummaryCard video={row.video} key={row.id}/>
                        {<Button onClick={() => fetchLoadPre(row.video.id)}>Details</Button>}
                        {attachedEntities.length > 1 && <Button onClick={() => startRemoveEntity(row)}>Remove from issue</Button>}
                    </Box>
                ))}
            </Box>
        )
    }


    const [dataType, setDataType] = useState(0);
    const [dataObject, setDataObject] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [loading2, setLoading2] = useState(false);

    const toggleDrawer2 = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDataType(0)
        setOpen(open);
    };


    const loadData = async (type, id) => {
        setOpen(true)
        toggleDrawer2(true);

        let url = "";
        if(type === 1){
            url = '/api/channels/details/' + id;
        }else if (type === 2){
            //TODO: Support multiple channels here - maybe get it from the data object itself
            window.open('https://outboundl.ink/?https://youtube.com/watch?v=' + id.video_id,'_blank');
            return;
        }else if (type === 3){

        }
        //1 : channel
        //2 : video
        //3 : issue

        setLoading2(true);
        const response = await fetch(url, {

        });
        const data = await response.json();
        setDataType(type);
        setDataObject(data);
        setLoading2(false);
    }

    const channelDetail = () => {
        return (
            <Box>
                <ChannelDetails channel={dataObject} />
            </Box>
        )
    }
    
    function loadedView() {
        return (
            <div>
                <Box sx={{flexGrow: 1}}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>

                            <Typography variant="h4" component="h2" sx={{mt: 3, mb: 2}}>
                                Entities
                            </Typography>
                            
                                {videoView()}

                                {channelView()}
                            <Divider sx={{my:3}} />
                            <Typography variant="h4" component="h2" sx={{mt: 3, mb: 2}}>
                                Add update
                            </Typography>
                            <AddIssueUpdate issue={issue} cb={callback}/>
                            <Divider sx={{my:3}} />
                            <Typography variant="h4" component="h2" sx={{mt: 3, mb: 2}}>
                                History
                            </Typography>
                            {issue.events.map((row) => (
                                <IssueEvent issueEvent={row} issue={issue} key={row.id} cb={callback}/>
                            ))}
                        </Grid>
                        <Grid item xs={4}>
                            {!loading && (
                                <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <CalendarMonthIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Opened"
                                                      secondary={moment(issue.date_opened).format('Do MMMM YYYY, h:mm:ss a')}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LockIcon/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary="Status" secondary={<IssueStatusDisplay status={issue.status} />} />
                                    </ListItem>
                                    {issue.assigned_user && (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Person2Icon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Assigned"
                                                          secondary={issue.assigned_user.display_name}/>
                                        </ListItem>
                                    )}
                                    {!issue.assigned_user && issue.is_system_generated && (
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <Person2Icon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="System generated"
                                                          secondary="Yes"/>
                                        </ListItem>
                                    )}
                                </List>
                            )}
                            <Card sx={{mt: 3}}>
                                <CardContent>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        People involved
                                    </Typography>
                                    {!loading && issue.involved_users.map((row) => (
                                        <Avatar sx={{width: 32, height: 32}}
                                                key={row.id}>{row.display_name.substring(0, 1)}</Avatar>
                                    ))}
                                </CardContent>
                            </Card>

                            <Card sx={{mt: 3}}>
                                <CardContent>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        Change Status
                                    </Typography>
                                    <Select
                                        value={newIssueStatus}
                                        label="Status" fullWidth
                                        onChange={handleNewIssueStatusChange}
                                    >
                                        {issueStatus.map((issue) => {
                                            return (
                                                <MenuItem value={issue.key} key={issue.key}>{issue.text}</MenuItem>
                                            );
                                        })}
                                    </Select>
                                </CardContent>
                            </Card>
                            <Card sx={{mt: 3}}>
                                <CardContent>
                                    <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                        Review Date
                                    </Typography>

                                    {issue.review_date && (
                                        <div>
                                            <Typography sx={{fontSize: 12}} gutterBottom
                                                        sx={{mb: 3, fontWeight: 'bold'}}>
                                                Item is being held
                                                until {moment(issue.review_date).format('MMMM Do YYYY, h:mm:ss a')}
                                            </Typography>
                                            <Button variant="outlined" onClick={handleResetReviewDate}>
                                                Reset
                                            </Button>
                                            <Typography variant="h6" component="h6" sx={{mt: 3, mb: 2}}>
                                                Set review date
                                            </Typography>
                                        </div>
                                    )}


                                    <DatePicker

                                        label="Review Date"
                                        value={holdValue}
                                        format="MM-DD-YYYY"
                                        disablePast
                                        onChange={(newValue) => setHoldValue(newValue)}
                                    />
                                    <Button variant="outlined" onClick={handleSaveReviewDate}>
                                        Save
                                    </Button>

                                </CardContent>
                            </Card>
                        </Grid>

                    </Grid>
                </Box>
                <Dialog
                    open={confirmRemove}
                    onClose={() => closeDiagloeRemove()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Remove entity from Issue?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to remove this entity from the issue?
                            
                            When you remove an entity from an issue, it will become visible again in all projects it was reported too. 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() =>closeDiagloeRemove()}>No</Button>
                        <Button onClick={() =>doRemoveEntity()} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Drawer

                    sx={{
                        zIndex: 1000,
                        width: {xs:'100%', md:"65%"},
                        [`& .MuiDrawer-paper`]: {
                            width: {xs:'100%', md:"65%"},
                        },
                    }}
                    anchor='right'
                    open={drawOpen} containerStyle={styles.navBar}
                    onClose={toggleDrawer(false)}
                >
                    <DrawerHeader>
                        <IconButton onClick={toggleDrawer(false)}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <strong>Video Review: &nbsp;</strong>
                        {project.name}
                    </DrawerHeader>
                    
                    {reportDetails && reportDetails.report_entry_id && <Box>
                        <Typography sx={{p:1, fontSize:16}} component="h6" variant="h6" >{reportDetails.title_at_report}</Typography>
                        <Divider />
                        <ReportEntry report={reportDetails} project={project} readonly={true}/>
                    </Box>}
                    {(!reportDetails  || !reportDetails.report_entry_id ) && <LinearProgress />}
                </Drawer>

                <Fragment>
                    <CssBaseline />
                    <Drawer
                        sx={{
                            zIndex: 1000,
                            width: {xs:'100%', md:"65%"},
                            [`& .MuiDrawer-paper`]: {
                                width: {xs:'100%', md:"65%"},
                            },
                        }}
                        anchor='right'
                        open={open}
                        onClose={toggleDrawer2(false)}
                    >
                        <DrawerHeader>
                            <IconButton  onClick={toggleDrawer2(false)}>
                                <ChevronLeftIcon />
                            </IconButton>
                            Details
                        </DrawerHeader>
                        <Box sx={{px: 2}}>
                            {dataType === 1 && channelDetail()}
                        </Box>
                    </Drawer>

                </Fragment>

                <SimpleBackdrop open={loading2}/>
            </div>
        );
    }

    return (<div>{loading ? loadingView() : loadedView()}</div>);
}