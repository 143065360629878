import * as React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as PropTypes from "prop-types";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import MDEditor, { commands } from '@uiw/react-md-editor';
import authService from '../api-authorization/AuthorizeService'
import {useParams} from "react-router-dom";
import moment from "moment/moment";
import {useEffect, useRef} from "react";
import SimpleBackdrop from "../backdrop";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { FileUploader } from "react-drag-drop-files";
import VideoSummaryCard from "../videoSummary";
import AddIssueUpdate from "../issues/addIssueUpdate";
import IssueEvent from "../issues/issueEvent";
import List from "@mui/material/List";
import {ListItem, ListItemAvatar, Select} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemText from "@mui/material/ListItemText";
import LockIcon from "@mui/icons-material/Lock";
import IssueStatusDisplay from "../issues/issueStatusDisplay";
import Person2Icon from "@mui/icons-material/Person2";
import MenuItem from "@mui/material/MenuItem";
import {DatePicker} from "@mui/x-date-pickers";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Slide from '@mui/material/Slide';
import AnnotateScreenDialog from "../ai/annotatedialog";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import {HelpText} from "../HelpText";
import Annotator from "../ai/annotator";
import Stack from "@mui/material/Stack";
import FormDialog from "./formdialog";
import StyledActionButton from "../GreenButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import Alert from "@mui/material/Alert";
import {StyledMenu} from "../styledMenu";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


PersonAddIcon.propTypes = {fontSize: PropTypes.string};


const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];


export default function BrandDetails({updateTitle}) {
    const [project, setProject] = React.useState({keywords: []});
    const [brand, setBrand] = React.useState();
    const {id} = useParams();
    const [loading, setLoading] = React.useState(true);

    const [files, setFiles] = React.useState([]);
    const uploadFile = async (file) =>{

        const formData = new FormData();
        formData.append('files', file[0]);
        const response = await fetch('/api/organisation/brands/' + id + '/logos', {
            method: 'POST',
            body: formData,
        });
        console.log(response);
        return response;
    }

    
    const handleChange = (file) => {
        setFiles(prevArray => [...files, file])
        console.log(file[0])
        uploadFile(file) .then(data => {
            console.log('Data received:', data);
        })
            .catch(error => {
                console.error('Error:', error);
            });
        
        console.log(files);
    };
    const [uploadStatus, setUploadStatus] = React.useState([]);

    const [queue, setQueue] = React.useState([]);
    const queueRef = useRef(null);
    const upload = () => {
        
        console.log(queue);
        if(queueRef.current.length == 0){
            fetchData(true)
                // make sure to catch any error
                .catch(console.error);
            return;
        }
        let q = queueRef.current;
        console.log(q);
        let file = q[0];
        setQueue(q.filter(f => f !== file));
        queueRef.current = q.filter(f => f !== file)
        console.log('uploading file:', file);
        const formData = new FormData();
        formData.append('files', file);
        fetch('/api/organisation/brands/' + id + '/usage', {
            method: 'POST',
            body: formData,
        }).then(data => {
                console.log('Data received:', data);
                // Update the status of the file in uploadStatus to 'Complete'
                setUploadStatus(prevStatus => prevStatus.map(item =>
                    item.name === file.name ? { ...item, status: 'Complete' } : item
                ));
                
                setTimeout(() => {
                    upload();
                }, 500);
            })
            .catch(error => {
                console.error('Error:', error);
                // Update the status of the file in uploadStatus to 'Error'
                setUploadStatus(prevStatus => prevStatus.map(item =>
                    item.name === file.name ? { ...item, status: 'Error' } : item
                ));
            });
    };
    const handleChangeUsage = (files) => {
        // Create a queue of files to be uploaded
        console.log(files);
        let f = [];
        for (let i = 0; i < files.length; i++)
        {
            let file = files[i];
            setUploadStatus(prevStatus => [...prevStatus, {name: file.name, status: 'Pending...'}]);
            f.push(file);
        }
        setQueue(f);
        queueRef.current = f;
        // Function to upload a file
   
            upload();
        
    };

    const fetchData = async (isLoading) => {
        if(isLoading) {
            setLoading(true);
        }
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/organisation/brands/' + id + '/details', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setBrand(data);
        updateTitle(data.name);
        if(isLoading){
            setLoading(false);
        }
    }


    useEffect(() => {
        fetchData(true)
            // make sure to catch any error
            .catch(console.error);

    }, [id])

    


    function loadingView() {
        return (<div>Loading.. <SimpleBackdrop open={loading}/></div>)
    }

    const [open, setOpen] = React.useState(false);
    const [logoId, setLogoId] = React.useState(-1);

    const [nextIndex, setNextId] = React.useState(-1);
    const [usageId, setUsageId] = React.useState('none');
    const handleClickOpen = (id, type, idx) => {
        setLogoId(id)
        console.log(id)
        setUsageId(type);
        setOpen(true);
        setCurrentIndex(idx);
        
        setCurrentItem(brand.images[idx])
    };

    const handleClose = () => {
        setOpen(false);

        fetchData(false)
            // make sure to catch any error
            .catch(console.error);

    };
    const onSaveDialog = (row, type) => {
        //setOpen(false);
        console.log('onSaveDialog');
        var items = type == 'logo' ? brand.images.filter(e=>e.image_type === 0) : brand.images.filter(e=>e.image_type === 1);
        var index = items.findIndex(x => x.id === row);
        
        if (index < items.length - 1) {
            setNextId(index+1);
            console.log("Next index exists");
            setTimeout(() => {
                //setLogoId(nextIndex);
                //setOpen(true);
            }, 200); // Delay of 500ms
        } else {
            console.log("Next index does not exist");
            //setOpen(false);
            //setNextId(-1);
        }
    };

    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [currentItem, setCurrentItem] = React.useState(null);
    const loadNextItem = () => {
        var ni = currentIndex + 1;
        if (currentIndex < brand.images.length - 1) {
            setCurrentIndex(ni);

        } else {
            setCurrentIndex(0);
        }
        console.log('index set to:', currentIndex)
        setCurrentItem(brand.images[ni]);
        console.log('next time', ni);
    };
    const loadPrevItem = () => {
        var ni = currentIndex - 1;
        if (ni > brand.images.length - 1) {
            setCurrentIndex(ni);

        } else {
            setCurrentIndex(0);
        }
        console.log('index set to:', currentIndex)
        setCurrentItem(brand.images[ni]);
        console.log('next time', ni);
    };
    
    const brandEditCb = () => {}

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
    const [handleEditMode, setHandleEdit] = React.useState(false);
    const handleEdit = () => {
        setAnchorEl(null);
        setHandleEdit(true);
    };
    
    const handleCloseEdit = () => {
        setHandleEdit(false)
        setAnchorEl(null);
    }
   function loadedView() {
        return (
            
            <div>
                <FormDialog brand={brand} cb={brandEditCb} openEditMode={handleEditMode} onClose={handleCloseEdit} />
                <Stack component="div" alignItems={"center"}>
                    <StyledActionButton
                        id="demo-customized-button"
                        aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}
                        sx={{fontSize:14}}
                    >
                        Options
                    </StyledActionButton>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={handleEdit} disableRipple>
                            <EditIcon />
                            Edit
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                    </StyledMenu>
                    {project.project_status === 1 && (
                        <Stack sx={{pt:2}} alignItems={"center"}>
                            <Alert severity="warning">
                                <Typography>This project is archived.</Typography>
                                <Typography sx={{fontSize:12}}>{project.archived_by.display_name} archived it on {moment(project.date_project_archived).format('llll')}</Typography>
                            </Alert>
                        </Stack>
                    )}
                </Stack>
                <Box sx={{flexGrow: 1, mt:2}}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            
                           
                            <div>
                                <TableContainer component={Paper} sx={{mt:'0em'}}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>File</TableCell>
                                                <TableCell align="right">Annotations</TableCell>
                                                <TableCell align="right">Edit</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {brand.images.map((row, idx) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.filename}
                                                    </TableCell>
                                                    <TableCell align="right">{row.annotations}</TableCell>
                                                    <TableCell align="right">
                                                        <Button variant="outlined" onClick={(e)=>handleClickOpen(row, 'usage', idx)}>
                                                            Open
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {brand.images.filter(e=>e.image_type === 1).length ==0 && (
                                                <TableRow
                                                    key="-1"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" colSpan="20">
                                                        No usage examples have beeen uploaded yet
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                
                                
                            </div>

                            <HelpText>
                                Upload any posters, photos or real world examples of where your logos are used. This
                                could screenshots, photos, prints. The more you upload, and the more varied they are the
                                better and more accurate matches will be.

                                <p>Think about different colours, backgrounds, usages.</p>
                            </HelpText>
                            
                            
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Upload new assets</Typography>
                            <FileUploader handleChange={handleChangeUsage} name="file" types={fileTypes} multiple={true} />

                            {uploadStatus.length > 0 && (
                                <div>
                                    <h2>Upload Status</h2>
                                    <ul>
                                        {uploadStatus.map((file, index) => (
                                            <li key={index}>{file.name}: {file.status}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Box>


                <Dialog
                    open={open}
                    onClose={handleClose}

                    PaperProps={{
                        sx: {
                            width: "100%",
                            maxWidth: "1400px!important",
                        },
                    }}
                >
                    {currentItem && (<Box>
                        
                        <Annotator img={currentItem} imageUrl={'/api/organisation/brands/img/' + currentItem.id}
                                   image_id={currentItem.id}
                                   onSave={onSaveDialog}
                                   brand_id={id}/>

                        <Stack direction={"row"}  justifyContent="space-between"
                               alignItems="center">
                            <Button onClick={loadPrevItem}>&#8592; Go Back</Button>
                            <Button onClick={handleClose}>Close</Button>
                            <Button onClick={loadNextItem}>Next &#8594;</Button>
                        </Stack>
                    </Box>)}

                    {/*<AnnotateScreenDialog row={brand.images[currentIndex]} loadNext={loadNext} 
                    onSave={onSaveDialog} brand_id={id}  />*/}
                </Dialog>
            </div>
        );
    }

    return (<div>{loading ? loadingView() : loadedView()}</div>);
}