const PctChangeArrowTable = ({ data }) => {

    if(!data){
        return (<span>a</span>)
    }
    
    const sortedDates = data ? Object.keys(data).sort((a, b) => new Date(a) - new Date(b)) : [];
    const oldestValue = data[sortedDates[sortedDates.length - 1]];
    const newestValue = data[sortedDates[0]];
    const percentChange = ((newestValue - oldestValue) / oldestValue) * 100;
    const isPositive = percentChange < 0;

    
    return (
        <span style={{display: 'inline-flex', alignItems: 'center', fontSize: '12px'}}>
      <span style={{color: isPositive ? 'red' : 'green', marginRight: '2px'}}>
        {isPositive ? '▲' : '▼'}
      </span>
      <span>{Math.abs(percentChange).toFixed(1)}%</span>
    </span>
    );
};

export default PctChangeArrowTable