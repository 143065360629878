import * as React from 'react';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: '#279100',
    fontSize: 16
}));

export default function StyledActionButton(props) {
    return <StyledButton {...props} />;
}