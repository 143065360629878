import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import authService from '../api-authorization/AuthorizeService'
import CreateMember from "./createMember";
import Avatar from "@mui/material/Avatar";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from "moment/moment";
import {HelpText} from "../HelpText";
import SimpleBackdrop from "../backdrop";
export default function Organisation({updateTitle}) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  
  const refresh = (updatedItems) => {
    console.log('refresh');
    console.log(updatedItems);
  };
  const columns = [
    { id: 'display_name1', label: 'User Id'},
    { id: 'display_name2', label: 'Display Name'},
    { id: 'display_name3', label: 'Enabled'},
    { id: 'display_name4', label: 'Roles'}
  ];
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchData = async () => {
    setLoading(true);
    //const token = await authService.getAccessToken();
    const response = await fetch('/api/organisation/members', {
      headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' }
    });
    authService.followIfLoginRedirect(response);
    const data = await response.json();
    setItems(data);
    setLoading(false);
  }
  
  useEffect(() => {
    // declare the async data fetching function
    updateTitle('Organisation'); 
    

    // call the function
    fetchData()
        // make sure to catch any error
        .catch(console.error);;
  }, [])
  
    const createCallback = () => {
      fetchData()
          // make sure to catch any error
          .catch(console.error);;
    }

    return (
        <div>
          <CreateMember cb={createCallback}/>
          <Paper sx={{ width: '100%', overflow: 'hidden', mt:2 }}>
            <TableContainer sx={{ maxHeight: 700 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow >
                    <TableCell></TableCell>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                    ))}
                    <TableCell key={"h1"}>Date Added</TableCell>
                    <TableCell key={"h2"}>Last Login</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                            
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                              <TableCell>
                                <Link to={`/issues/${row.id}`}>
                                  <Avatar sx={{ width: 32, height: 32 }}>{row.first_name.substring(0,1).toUpperCase()}</Avatar>
                                </Link>
                              </TableCell>
                              <TableCell>
                                {row.id}
                              </TableCell>
                              <TableCell>
                                {row.display_name}
                              </TableCell>
                              <TableCell>
                                {row.locked ?
                                    <CheckCircleIcon color="primary" /> : <CancelIcon color="disabled"/>
                                        }
                              </TableCell>
                              <TableCell>
                                {row.roles.join(', ')}
                              </TableCell>
                              <TableCell>
                                {moment(row.date_created).format('MMMM Do YYYY, h:mm:ssa')}
                              </TableCell>
                              <TableCell>
                                {moment(row.date_last_login).fromNow()}
                              </TableCell>
                            </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <HelpText>
           You can share Digitals with your team members, add them here. When you add a user you can choose specific roles. 
            
            They will have their username sent to them automatically with a link to login. 
            
            Their username will be their email address. 👍
          </HelpText>

          <SimpleBackdrop  open={loading}/>
        </div>
    );
  
}
