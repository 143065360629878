import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {LoginMenu} from "./api-authorization/LoginMenu";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {Link} from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import BugReportIcon from "@mui/icons-material/BugReport";
import PersonIcon from "@mui/icons-material/Person";
import authService from '../components/api-authorization/AuthorizeService'
import Inventory2Icon from '@mui/icons-material/Inventory2';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import AssessmentIcon from '@mui/icons-material/Assessment';

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (

        <AppBar position="static" elevation={0}>
            <Container maxWidth="none" spacing={0} sx={{paddingLeft:"10px !important",paddingRight:"10px !important"}}>
               

                    <Stack   direction="row"
                             justifyContent="space-between"
                             alignItems="center"
                             spacing={0} sx={{mt:0.5}}>
                      
                        <Stack  direction={"row"} justifyContent="center"
                                 alignItems="center" sx={{minWidth:"150px"}}>
                            <Box  component="img" src={"/digitalslogov2_notag.png"}  sx={{  mt: 0, height:'30px' }} />
                            <Box sx={{display: {xs: 'block', md: 'none'}}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: {xs: 'block', md: 'none'},
                                    }}
                                >
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Link component={"a"}  to="/" underline="none" sx={{mr:2, display:{md:'block'}}} >
                                            <Typography><DashboardIcon sx={{verticalAlign:"middle"}}/> Dashboard</Typography>
                                        </Link>
                                    </MenuItem>
                                    {authService.isInRole(["BASIC"]) && (
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Link component={"a"}  to="/projects" sx={{mr:5, display:{md:'block'}}}>
                                                <Typography><AccountTreeIcon  sx={{verticalAlign:"middle"}}/> Projects</Typography>
                                            </Link>
                                        </MenuItem>
                                    )}

                                    {authService.isInRole(["BASIC"]) && (
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Link tag={Button}  to="/channels">
                                                <Typography><CastConnectedIcon sx={{verticalAlign:"middle"}}/> Channels</Typography>
                                            </Link></MenuItem>)}

                                    {authService.isInRole(["BASIC"]) && (
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Link tag={Button}  to="/issues">
                                                <Typography><BugReportIcon sx={{verticalAlign:"middle"}}/> Issues</Typography>
                                            </Link></MenuItem>)}

                                    {authService.isInRole(["BASIC"]) && (
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Link tag={Button}  to="/brands">
                                                <Typography><Inventory2Icon sx={{verticalAlign:"middle"}}/> Brands</Typography>
                                            </Link></MenuItem>)}

                                    {authService.isInRole(["ADMIN"]) && (
                                        <MenuItem onClick={handleCloseNavMenu}>
                                            <Link tag={Link}  to="/organisation">

                                                <Typography><PersonIcon sx={{verticalAlign:"middle"}}/> Organisation</Typography>
                                            </Link></MenuItem>)}
                                </Menu>
                            </Box>
                        </Stack>
                      
                
                        <Grid  container
                               direction="row"
                               justifyContent="center"
                               alignItems="center">
                            <Box  sx={{display: {xs: 'none', md: 'block'},  border: 0}}>
                                <Stack direction="row" spacing={2} className={'nav'} justifyContent="center"
                                       alignItems="center">
                    
                                    <Link component={"a"}  to="/" underline="none" sx={{mr:2, display:{md:'block'}}} >
                                        <Typography sx={{fontSize:15, fontWeight:700}}><DashboardIcon sx={{verticalAlign:"middle"}}/> Home</Typography>
                                    </Link>
                    
                                    {authService.isInRole(["BASIC"]) && (
                                        <Link tag={Button}  to="/channels">
                                            <Typography sx={{fontSize:15, fontWeight:700}}><CastConnectedIcon sx={{verticalAlign:"middle"}}/> Channels</Typography>
                                        </Link>)}
                    
                                    {authService.isInRole(["BASIC"]) && (
                                        <Link tag={Button}  to="/issues">
                                            <Typography sx={{fontSize:15, fontWeight:700}}><BugReportIcon sx={{verticalAlign:"middle"}}/> Issues</Typography>
                                        </Link>)}
                    
                                    {authService.isInRole(["BASIC"]) && (
                                        <Link tag={Button}  to="/brands">
                                            <Typography sx={{fontSize:15, fontWeight:700}}><Inventory2Icon sx={{verticalAlign:"middle"}}/> Brands</Typography>
                                        </Link>)}
                                    {authService.isInRole(["BASIC"]) && (
                                        <Link tag={Button}  to="/reporting">
                                            <Typography sx={{fontSize:15, fontWeight:700}}><AssessmentIcon sx={{verticalAlign:"middle"}}/> Reporting</Typography>
                                        </Link>)}
                                </Stack>
                            </Box>
                        </Grid>

                        <Stack  sx={{minWidth:"150px"}}  direction={"row"} justifyContent="end"
                                alignItems="center">

                            <LoginMenu></LoginMenu>

                        </Stack>
                    </Stack>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;
