import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MuiChipsInput } from 'mui-chips-input'
import authService from '../api-authorization/AuthorizeService'
import StyledActionButton from "../GreenButton";
import {useEffect} from "react";
import moment from "moment/moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Title from "../Title";
export default function FormDialog({cb, existing}) {

    const [ items, setItems] = React.useState([])
    const [open, setOpen] = React.useState(existing && existing.id);
    const [projectName, setProjectName] = React.useState("");
    const [brands, setBrands] = React.useState([]);
    const [existingProject, setExistingProject] = React.useState({project_brands:[]});
    const [ channels, setChannels] = React.useState([])
    const handleClickOpen = async () => {

        
        setOpen(true);
    };
    const fetchChannels = async () => {
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/channels', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        const data = await response.json();
        setChannels(data);

    }
    const fetchBrands = async () => {
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/organisation/brands', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        const data = await response.json();
        setBrands(data);

    }
    const fetchData = async (id) => {
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/projects/details/' + id, {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        const data = await response.json();
        setExistingProject(data);
        authService.followIfLoginRedirect(response);
        setProjectName(data.name);
        setChips(data.keywords);
        setOpen(true);
        fetchChannels();
        fetchBrands();
    }
    
    useEffect(() => {
            if(existing){
                fetchData(existing.id)
            }else{
                fetchChannels();
                fetchBrands();
            }
        
    }, [existing])
    const handleClose = () => {
        setOpen(false);
    };
    const [chips, setChips] = React.useState([])

    const handleChange = (newChips) => {
        setChips(newChips)
    }
    const handleNameChange = (e) => setProjectName(e.target.value);
    const create = async (event) => { 
        console.log(chips);
        console.log(projectName);
        //const newItems = items;
        //newItems.push({name: projectName})
        //setItems(newItems);


        var req = {
            name: projectName,
            keywords: chips,
            project_brands: existingProject.project_brands.map(e=>e.id),
            project_channels:  existingProject.project_channels.map(e=>e.id)
        }
        //const token = await authService.getAccessToken();
        const response = await fetch(existing? '/api/projects/' + existing.id : '/api/projects', {
            method: existing ? "PUT" : "POST",
            headers: {/*'X-Authorization': `Bearer ${token}`,*/ 'Content-Type': 'application/json' },
            body: JSON.stringify(req)
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        //this.setState({ items: data, loading: false });
        setOpen(false);
        setChips([]);
        setProjectName("");
        if(cb){
            cb();
        }
       
    }

    const handleChangeBrand = (id) => {
        var newBrands = existingProject.project_brands;
        if(newBrands.filter(e=>e.id == id).length > 0){
            newBrands = newBrands.filter(e=>e.id != id);
        }else{
            newBrands.push({id: id});
        }
        setExistingProject({...existingProject, project_brands: newBrands});
        console.log(existingProject);
    };
    const handleChangeChannel = (id) => {
        var newChannels = existingProject.project_channels;
        if(newChannels.filter(e=>e.id == id).length > 0){
            newChannels = newChannels.filter(e=>e.id != id);
        }else{
            newChannels.push({id: id});
        }
        setExistingProject({...existingProject, project_channels: newChannels});
        console.log(existingProject);
    };
    return (
        <div>
            {(!existing || existing && !existing.id) && (<StyledActionButton onClick={handleClickOpen}>
                    Create
                </StyledActionButton>)}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{existing ? "Edit" : "Create"} Project</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Project Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={projectName}
                        onChange={handleNameChange}
                    />
                    
                    <DialogContentText  sx={{mt:3}}>
                        Enter keywords for the project. These keywords will be words that videos will be searched for.
                    </DialogContentText>
                    
                    <MuiChipsInput margin="dense" label="Keywords" variant="standard" fullWidth={true} value={chips} onChange={handleChange}
                                   
                                   
                    />
                    <Title >Brands</Title>
                    <DialogContentText >
                        Here you can select a brand to associate with the project. This will allow us to report visual mentions of brands in the media 
                        even if a label doesn't qualify. 
                    </DialogContentText>
                    
                    {brands.length > 0 && brands.map(e=>(
                        <FormControlLabel key={e.id}
                            control={
                                <Checkbox key={'cb' + e.id} checked={existingProject && existingProject.project_brands.filter(f=>f.id == e.id).length > 0} name={e.name} onChange={()=>handleChangeBrand(e.id)} />
                            }
                            label={e.name}
                        />  
                    ))}

                    <Divider sx={{my:3}} />
                    <Title >Channels</Title>
                    <DialogContentText >
                        Limit project to certain channels. Select channels below. only channels identified below will be reported to this project.
                    </DialogContentText>
                    {channels.length > 0 && channels.map(e=>(
                        <FormControlLabel key={e.channel.id}
                                          control={
                                              <Checkbox key={'cbchan' + e.channel.id} checked={existingProject && existingProject.project_channels.filter(f=>f.id == e.channel.id).length > 0} name={e.channel.handle} onChange={()=>handleChangeChannel(e.channel.id)} />
                                          }
                                          label={e.channel.handle}
                        />
                    ))}
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={create}>{!existing ? "Create" : "Save"}</Button>
                </DialogActions>
            </Dialog>
            
        </div>
    );
}