import React, { Component, useCallback, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from "react-router-dom";
import authService from '../api-authorization/AuthorizeService'
import {HelpText} from "../HelpText";
import SimpleBackdrop from "../backdrop";
import Box from "@mui/material/Box";
import RandomQuote from "../randomQuote";
import FormDialog from "./formdialog";
export default function Brands({updateTitle}) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [items, setItems] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const refresh =(updatedItems) => {
        console.log('refresh');
        console.log(updatedItems);
        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);;


    };
    const columns = [
        { id: 'name', label: 'Name', minWidth: 170 }
    ];
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchData = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken();
        const response = await fetch('/api/organisation/brands', {
            //headers: !token ? {} : {'X-Authorization': `Bearer ${token}`} }
        });
        authService.followIfLoginRedirect(response);
        const data = await response.json();
        setItems(data);
        setLoading(false);
    }

    useEffect(() => {
        // declare the async data fetching function
        updateTitle('Brands');


        // call the function
        fetchData()
            // make sure to catch any error
            .catch(console.error);;
    }, [])

    const noEntries = () => {
        return (
            <TableRow>
                <TableCell colSpan={10}>
                    You haven't yet added any Brands yet
                    <Box mt={5}>
                        <RandomQuote />
                    </Box>
                </TableCell>
            </TableRow>
        )
    }
    return (
        <div>
            {authService.isInRole(["ADMIN", "CREATEPROJECT"]) && (
                <FormDialog items={items} cb={refresh} />
            )}
            
            <Paper sx={{ width: '100%', overflow: 'hidden', mt:2 }}>

         
                
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow key="r">
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>

                                            <TableCell>
                                                <Link to={`/brands/${row.id}`} updateTitle={updateTitle} >{row.name}</Link>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {(!items || items.length === 0) && noEntries()}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <HelpText>
                These are specific channels you are subscribed too.
            </HelpText>
            <SimpleBackdrop  open={loading}/>
        </div>
    );

}
